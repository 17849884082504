import {AfterViewInit, Component} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectIsEdiRealTokenValid,
  selectIsJustimmoTokenValid, selectIsMicrosoftTokenValid,
  selectIsRemaxTokenValid,
} from 'src/app/store/_global/global.selectors';
import { ExternalSystem } from 'src/app/common-components/integration-wrapper/integration-wrapper.component';
import { AccessService } from "services/access-service";
import {
  selectIsEdiReal,
  selectIsJustimmo,
  selectIsMicrosoft,
  selectIsRemax
} from "../../store/settings/settings.selectors";
import {combineLatest} from "rxjs";
import {RestoreConnectionDialog} from "../../common-components/restore-connection-dialog/restore-connection-dialog";
import {MatDialog} from "@angular/material/dialog";
import {AccountLinkingService} from "services/account-linking.service";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'integration-statuses',
  templateUrl: './integration-statuses.component.html',
})
export class IntegrationStatusesComponent implements AfterViewInit {
  isEdiRealTokenValid$ = this._store.select(selectIsEdiRealTokenValid);
  isRemaxTokenValid$ = this._store.select(selectIsRemaxTokenValid);
  isJustimmoTokenValid$ = this._store.select(selectIsJustimmoTokenValid);
  isMicrosoftTokenValid$ = this._store.select(selectIsMicrosoftTokenValid);
  hasFreeVersion$ = this.accessService.hasFreeVersion$;

  ExternalSystem = ExternalSystem;

  isEdiReal$ = this._store.select(selectIsEdiReal);
  isJustimmo$ = this._store.select(selectIsJustimmo);
  isRemax$ = this._store.select(selectIsRemax);
  isMicrosoft$ = this._store.select(selectIsMicrosoft);

  ediLink = this._accountLinkingService.generateLink('edi-real', window.location.href);
  remaxLink = this._accountLinkingService.generateLink('remax-idp', window.location.href);
  justimmoLink = this._accountLinkingService.generateLink('justimmo', window.location.href);
  microsoftLink = this._accountLinkingService.generateLink('microsoft', window.location.href);

  constructor(
    private readonly _store: Store,
    public accessService: AccessService,
    public dialog: MatDialog,
    private readonly _accountLinkingService: AccountLinkingService
  ) { }

  ngAfterViewInit() {
    combineLatest([
      this.isRemax$,
      this.isRemaxTokenValid$
    ]).pipe(debounceTime(300)) // Adjust debounce time as needed
      .subscribe(([hasConnected, connectionValid]) => {
      if (hasConnected && !connectionValid) {
        this.openDialog('RE/MAX', this.remaxLink);
      }
    });

    combineLatest([
      this.isMicrosoft$,
      this.isMicrosoftTokenValid$
    ]).pipe(debounceTime(300)) // Adjust debounce time as needed
      .subscribe(([hasConnected, connectionValid]) => {
      if (hasConnected && !connectionValid) {
        this.openDialog('Microsoft', this.microsoftLink);
      }
    });

    combineLatest([
      this.isEdiReal$,
      this.isEdiRealTokenValid$
    ]).pipe(debounceTime(300)) // Adjust debounce time as needed
      .subscribe(([hasConnected, connectionValid]) => {
      console.log('isEdiReal', hasConnected, 'isEdiRealTokenValid', connectionValid);
      if (hasConnected && !connectionValid) {
        this.openDialog('EDI-Real', this.ediLink);
      }
    });

    combineLatest([
      this.isJustimmo$,
      this.isJustimmoTokenValid$
    ]).pipe(debounceTime(300)) // Adjust debounce time as needed
      .subscribe(([hasConnected, connectionValid]) => {
      if (hasConnected && !connectionValid) {
        this.openDialog('Justimmo', this.justimmoLink);
      }
    });
  }

  openDialog(externalSystem: string, restoreLink: string): void {
    console.log('openDialog');
    const dialogRef = this.dialog.open(RestoreConnectionDialog, { data: { name: externalSystem, link: restoreLink } });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((option) => {
        if (option === 'restore') {
          dialogRef.close();

        } else {
          dialogRef.close();
        }
      });
  }
}
