import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { FormGroup } from '@angular/forms';

import { BusinessCustomerApiFacade } from './business-customer-api.facade';
import { BusinessCustomerFormService } from './business-customer-form.service';
import { BusinessCustomer, BusinessCustomerWithCustomersDetails } from './business-customer.model';
import { BusinessCustomerActions } from './store/action-types';
import { BusinessCustomerFeatureState, businessCustomerSelectors } from './store';

@Injectable({ providedIn: 'root' })
export class BusinessCustomerDataFacade {
  businessCustomers$ = this._store$.select(businessCustomerSelectors.customers);
  businessCustomersLoading$ = this._store$.select(businessCustomerSelectors.customersLoading);
  customerDetails$ = this._store$.select(businessCustomerSelectors.customerDetails);
  customerDetailsLoading$ = this._store$.select(businessCustomerSelectors.customerDetailsLoading);

  constructor(
    private readonly _store$: Store<BusinessCustomerFeatureState>,
    private readonly _businessCustomerApiFacade: BusinessCustomerApiFacade,
    private readonly _businessCustomerFormService: BusinessCustomerFormService
  ) { }

  loadBusinessCustomers(): void {
    this._store$.dispatch(BusinessCustomerActions.LoadBusinessCustomers());
  }

  loadBusinessCustomerDetails(id: string): void {
    this._store$.dispatch(BusinessCustomerActions.LoadBusinessCustomerDetails({ payload: { id } }));
  }

  searchBusinessCustomer$(companyNumber: string): Observable<BusinessCustomer[]> {
    return this._businessCustomerApiFacade.searchBusinessCustomer$(companyNumber);
  }

  getCustomerById$(id: string): Observable<BusinessCustomerWithCustomersDetails> {
    return this._businessCustomerApiFacade.getCustomerById$(id);
  }

  saveCustomer$(customerForm: FormGroup): Observable<BusinessCustomer> {
    customerForm.updateValueAndValidity();
    customerForm.markAllAsTouched();

    if (customerForm.invalid) {
      console.warn('Customer Form Invalid!', customerForm.value);
      return of();
    }

    const customer = this._businessCustomerFormService.prepareCustomerToPersist(customerForm.value);

    return this._businessCustomerApiFacade.createBusinessCustomer$(customer);
  }

  public deleteBusinessCustomer$(businessCustomerId: string): void {
    return this._store$.dispatch(BusinessCustomerActions.DeleteBusinessCustomer({ businessCustomerId }))
  }
}
