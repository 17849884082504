import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {Store} from "@ngrx/store";
import {RoleProviderService} from "services/role-provider.service";
import {AccessService} from "services/access-service";
import {selectProfile} from "../../store/profile/profile.selectors";
import {switchMap, take, tap} from "rxjs/operators";

@Component({
  selector: 'account-status',
  templateUrl: 'account-status.component.html',
  styles: [``],
  standalone: true,
  imports: [CommonModule],
})

export class AccountStatusComponent implements OnInit {
  @Input() title = 'Kontostatus';

  propupRole = this._roleProviders.getRoles()
  hasSpecialOffer = this._roleProviders.hasRole('propup-special-offer')

  showAccountInfo = {
    link: '',
    text: '',
    buttonText: '',
  };
  hasFreeVersion$ = this.accessService.hasFreeVersion$;
  newRegistrationDays = 0;
  constructor(
    private readonly _store: Store,
    private readonly _roleProviders: RoleProviderService,
    public accessService: AccessService,
  ) {
  }

  ngOnInit() {
    this._store.select(selectProfile).pipe(
      take(2),
      tap((p) => {
        const createdDate = p.createdTimeStamp
        const currentDate = Date.now()
        const diff = (currentDate - createdDate)
        const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        this.newRegistrationDays = diffDays
      }),
      switchMap(() => this.accessService.hasFreeVersion$),
      tap((hasFreeVersion) => { this.getAccountInfo(hasFreeVersion) })
    ).subscribe()
  }

  getAccountInfo(hasFreeVersion: boolean) {

    if (this.newRegistrationDays <= 14) {
      this.showAccountInfo = this.newRegistration
    } else {
      if (this.propupRole.includes('propup-test-user')) {
        this.showAccountInfo = this.propupTest
      } else if (this.propupRole.includes('propup-internal-user')) {
        this.showAccountInfo = this.propupInternal
      } else if (this.propupRole.includes('propup-special-offer')) {
        this.showAccountInfo = this.propupSpecial
      } else if (hasFreeVersion) {
        this.showAccountInfo = this.remaxFree
      }

      else {
        this.showAccountInfo = this.propupStandard
      }
    }
  }

  newRegistration = {
    link: 'https://calendly.com/mreikersdorfer/propup',
    text: '14 Tage Testversion',
    buttonText: 'Jetzt upgraden'
  }

  propupStandard = {
    link: 'https://calendly.com/mreikersdorfer/propup',
    text: 'Testversion abgelaufen',
    buttonText: 'Jetzt upgraden'
  }

  propupTest = {
    link: 'https://calendly.com/mreikersdorfer/propup',
    text: 'Testzugriff',
    buttonText: 'Jetzt upgraden'
  }

  propupInternal = {
    link: 'https://www.propup.at/',
    text: 'Interner PROPUP Account',
    buttonText: ''
  }

  remaxFree = {
    link: 'https://calendly.com/mreikersdorfer/propup',
    text: 'RE/MAX Gratisversion',
    buttonText: 'Jetzt upgraden'
  }

  propupSpecial = {
    link: 'https://calendly.com/mreikersdorfer/propup',
    text: 'RE/MAX Spezialangebot',
    buttonText: 'Jetzt upgraden'
  }

}
