import { BuyingOfferEmailVariables } from "src/app/domains/buying-offer/buying-offer.model";

const PROPERTY_ADDRESS = '{PROPERTY_ADDRESS}';
const DOCUMENT_URL = '{DOCUMENT_URL}';
const SIGNATURE = '{SIGNATURE}';

const mergeVariables = (template: string, vars: BuyingOfferEmailVariables) => {
  return template
    .replaceAll(PROPERTY_ADDRESS, vars.propertyAddress)
    .replaceAll(SIGNATURE, vars.signature)
    .replaceAll(DOCUMENT_URL, vars.documentUrl);
}

export const buildTemplate = (title: string, body: string, vars: BuyingOfferEmailVariables) => {
  const mail = {
    subject: mergeVariables(title, vars),
    body: mergeVariables(body, vars),
  };

  return mail;
}

export const BuyingOfferEmail = {
  buildTemplate,
  mergeVariables,
};