import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, map, mergeMap, tap } from 'rxjs/operators';

import { SnackbarService } from 'services/snackbar.service';
import { BuyingOfferApiFacade } from "../buying-offer-api.facade";
import * as DataActions from './actions';
import { Buyer } from "../buying-offer.model";
import { getAgentCompany } from "src/app/common-models/mapping";
import { AuthService } from "services/auth.service";
import { CustomerWithContact } from "@domains/customer";
import { EMPTY } from "rxjs";
import { DocumentsDataFacade } from "../../documents";
import { TranslationService } from "src/app/i18n/TranslationService";

@Injectable()
export class BuyingOfferEffects {
  public loadBuyingOffers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadBuyingOffers),
      switchMap(({ objectId }) =>
        this._buyingOfferApiFacade.loadBuyingOffers$(objectId).pipe(
          switchMap((value) => [DataActions.BuyingOffersLoaded({ payload: value })]),
          catchError(() => [DataActions.BuyingOfferDetailsLoadingFailed()])
        )
      )
    )
  );

  public loadBuyingOffersByCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadBuyingOffersByCustomer),
      switchMap(({ customerId }) =>
        this._buyingOfferApiFacade.loadBuyingOffersByCustomer$(customerId).pipe(
          switchMap((value) => [DataActions.BuyingOffersLoaded({ payload: value })]),
          catchError(() => [DataActions.BuyingOfferDetailsLoadingFailed()])
        )
      )
    )
  );

  public loadBuyingOfferDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadBuyingOfferDetails),
      switchMap(({ objectId, buyingOfferId }) =>
        this._buyingOfferApiFacade.loadBuyingOfferDetails$(objectId, buyingOfferId).pipe(
          switchMap((value) => [DataActions.BuyingOfferDetailsLoaded({ payload: value })]),
          catchError(() => [DataActions.BuyingOfferDetailsLoadingFailed()])
        )
      )
    )
  );

  public sendBuyerCustomerPortalNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendBuyerCustomerPortalNotification),
      switchMap(({ objectId, buyingOfferId }) =>
        this._buyingOfferApiFacade.sendBuyerCustomerPortalNotification$(buyingOfferId).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Nachricht erfolgreich gesendet',
              'mat-primary',
              true
            );
          }),
          switchMap(() => [DataActions.LoadBuyingOffers({ objectId })]),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Nachricht konnte nicht gesendet werden',
              'mat-warn',
              true
            );
            return [];
          }),
        )
      )
    )
  );

  public sendSellerOwnerPortalNotification$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendSellerOwnerPortalNotification),
      switchMap(({ objectId, buyingOfferId }) =>
        this._buyingOfferApiFacade.sendSellerOwnerPortalNotification$(buyingOfferId).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Nachricht erfolgreich gesendet',
              'mat-primary',
              true
            );
          }),
          switchMap(() => [DataActions.LoadBuyingOffers({ objectId })]),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Nachricht konnte nicht gesendet werden',
              'mat-warn',
              true
            );
            return [];
          }),
        )
      )
    )
  );

  public saveBuyingOffer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveBuyingOffer),
      switchMap(({ buyingOffer, isFinal }) => {
        const buyersWithoutCustomers = buyingOffer.buyers.map(
          (buyer: Buyer) => {
            const {
              id,
              title,
              firstname,
              lastname,
              birthdate,
              contact,
              nationality,
              pep,
              // @ts-ignore
              signature,
              ...buyerWithoutCustomer
            } = buyer;

            return {
              ...buyerWithoutCustomer,
              customerId: (buyer as any).customerId,
            };
          }
        );
        const sellersWithoutCustomers = buyingOffer.sellers.map(
          (seller: CustomerWithContact) => {
            const {
              id,
              title,
              firstname,
              lastname,
              birthdate,
              contact,
              nationality,
              pep,
              ...sellerWithoutCustomer
            } = seller;
            return {
              ...sellerWithoutCustomer,
              customerId: (seller as any).customerId,
            };
          }
        );

        const {
          signatureData,
          ...buyingOfferWithoutSignature
        } = buyingOffer;

        const { customerData, ...businessBuyer } = buyingOffer.businessBuyer ?? {};

        const buyingOfferWithoutCustomers = {
          ...buyingOfferWithoutSignature,
          buyers: buyersWithoutCustomers,
          sellers: sellersWithoutCustomers,
          businessBuyer,
        };

        return this._buyingOfferApiFacade.saveBuyingOffer$(buyingOfferWithoutCustomers).pipe(
          switchMap((bo) => {
            if (isFinal) {
              this._router.navigateByUrl(`/objects/${bo.objectId}/digital-offerings`);

              return [DataActions.SaveBuyingOfferSuccess(), DataActions.CreateBuyingOfferSucceded()];
            }
            return [DataActions.SaveBuyingOfferSuccess()];
          })
        );
      })
    ),
  );

  public buyingOfferCreationSucceded$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateBuyingOfferSucceded),
      map(() =>
        this._snackbarService.showSnackbar(
          `${this._translationService.instant('buying_offer')} erfolgreich erstellt`,
          'mat-primary',
          true
        )
      )
    ),
    { dispatch: false }
  );

  public updateBuyingOfferCustomers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.UpdateBuyingOfferCustomers),
      switchMap(({ objectId, buyingOfferId, buyers, sellers }) =>
        this._buyingOfferApiFacade.loadBuyingOfferDetails$(objectId, buyingOfferId).pipe(
          switchMap((buyingOffer) => {
            const updatedBuyers = buyingOffer.buyers.map((b, index) => ({
              ...b,
              customerId: buyers[index]?.id,
            }));
            buyingOffer.buyers = updatedBuyers;

            const updatedSellers = buyingOffer.sellers.map((s, index) => ({
              ...s,
              customerId: sellers[index]?.id,
            }));
            buyingOffer.sellers = updatedSellers;

            return [DataActions.SaveBuyingOffer({ buyingOffer, isFinal: true })];
          })
        )
      )
    )
  )

  deleteBuyingOffer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeleteBuyingOffer),
      mergeMap(({ buyingOfferId, objectId }) =>
        this._buyingOfferApiFacade.deleteBuyingOffer$(buyingOfferId).pipe(
          switchMap(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('buying_offer')} erfolgreich gelöscht`,
              'mat-primary',
              true
            );
            this._documentDataFacade.deleteBuyingOffer(buyingOfferId)
            return [
              DataActions.DeleteBuyingOfferSuccess({
                buyingOfferId
              }),
            ];
          }), catchError(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('buying_offer')} konnte nicht gelöscht werden`,
              'mat-warn',
              true
            );
            return [DataActions.DeleteBuyingOfferFailed({ buyingOfferId })];
          })
        )
      ),
    ),
  );

  public updateBuyingOfferMasterTemplate$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.UpdateBuyingOfferMasterTemplate),
      mergeMap(({ buyingOfferId, objectId }) =>
        this._buyingOfferApiFacade.updateBuyingOfferMasterTemplate$(buyingOfferId, objectId).pipe(
          switchMap(() => {
            this._snackbarService.showSnackbar(
              'Muster erfolgreich aktualisiert',
              'mat-primary',
              true
            );
            return [DataActions.LoadBuyingOffers({ objectId })];
          }), catchError(() => {
            this._snackbarService.showSnackbar(
              'Muster konnte nicht aktualisiert werden',
              'mat-warn',
              true
            );
            return [];
          })
        )
      ),
    ),
  );

  public resetBuyingOffer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.ResetBuyingOffer),
      mergeMap(({ objectId, buyingOfferId }) =>
        this._buyingOfferApiFacade.resetBuyingOffer$(objectId, buyingOfferId).pipe(
          switchMap(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('buying_offer')} erfolgreich aktualisiert`,
              'mat-primary',
              true
            );
            return [DataActions.LoadBuyingOffers({ objectId })];
          }), catchError(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('buying_offer')} konnte nicht aktualisiert werden`,
              'mat-warn',
              true
            );
            return [];
          })
        )
      ),
    ),
  );

  public createAndUploadBuyingOfferDocumentFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateAndUploadBuyingOfferDocumentFailed),
      map(() =>
        this._snackbarService.showSnackbar(
          `${this._translationService.instant('buying_offer')} PDF konnte nicht erzeugt werden`,
          'mat-warn',
          true
        )
      )
    ),
    { dispatch: false }
  );

  public trySignAndCompleteBuyingOffer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SignAndCompleteBuyingOffer),
      mergeMap(({ buyingOfferSignature }) =>
        this._buyingOfferApiFacade.signAndCompleteBuyingOffer$(
          buyingOfferSignature.objectId,
          buyingOfferSignature.buyingOfferId,
          buyingOfferSignature.buyersSignatureData,
          buyingOfferSignature.sellersSignatureData,
          buyingOfferSignature.sellersAcceptance,
          getAgentCompany(this._authService)
        ).pipe(
          tap((buyingOffer) => {
            this._router.navigateByUrl(
              `/objects/${buyingOffer.objectId}/digital-offerings/buying-offer/${buyingOffer.id}/email`
            );
          }),
          catchError(() => {
            this._router.navigateByUrl(`/objects/${buyingOfferSignature.objectId}/digital-offerings/buying-offer`);

            return EMPTY;
          })
        )),
    ),
    { dispatch: false }
  );

  public draftSignBuyingOffer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DraftSignBuyingOffer),
      mergeMap(({ buyingOfferSignature, openEmailDialog }) =>
        this._buyingOfferApiFacade.signAndCompleteBuyingOffer$(
          buyingOfferSignature.objectId,
          buyingOfferSignature.buyingOfferId,
          buyingOfferSignature.buyersSignatureData,
          buyingOfferSignature.sellersSignatureData,
          buyingOfferSignature.sellersAcceptance,
          getAgentCompany(this._authService)
        ).pipe(
          tap((buyingOffer) => {
            if (openEmailDialog) {
              this._router.navigateByUrl(
                `/objects/${buyingOffer.objectId}/digital-offerings/buying-offer/${buyingOffer.id}/email`
              );
            }
          }),
          catchError(() => {
            this._router.navigateByUrl(`/objects/${buyingOfferSignature.objectId}/digital-offerings/buying-offer`);

            return EMPTY;
          })
        )),
    ),
    { dispatch: false }
  );

  public sendBuyingOfferEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendBuyingOfferEmail),
      switchMap(({ buyingOfferId, emailData, agentCompany }) =>
        this._buyingOfferApiFacade.sendBuyingOfferEmail$(
          buyingOfferId,
          emailData,
          agentCompany
        ).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('buying_offer')} E-Mail erfolgreich gesendet`,
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              `${this._translationService.instant('buying_offer')} E-Mail konnte nicht gesendet werden`,
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  public sendCustomerPortalLink$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendCustomerPortalLink),
      switchMap(({ objectId, buyingOfferId, handInOffer, emailData }) =>
        this._buyingOfferApiFacade.sendCustomerPortalLink$(
          objectId,
          buyingOfferId,
          handInOffer,
          emailData,
        ).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Kundenportal E-Mail erfolgreich gesendet',
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Kundenportal E-Mail konnte nicht gesendet werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  public sendSellerCustomerPortalLink$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendSellerCustomerPortalLink),
      switchMap(({ objectId, buyingOfferId, emailData }) =>
        this._buyingOfferApiFacade.sendSellerCustomerPortalLink$(
          objectId,
          buyingOfferId,
          emailData,
        ).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Kundenportal E-Mail erfolgreich gesendet',
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Kundenportal E-Mail konnte nicht gesendet werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  public orderBuyingContract$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.OrderBuyingContract),
      switchMap(({ buyingOfferId, objectId }) =>
        this._buyingOfferApiFacade.orderBuyingContract$(buyingOfferId, objectId).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Kaufvertrag erfolgreich bestellt',
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Kaufvertrag konnte nicht bestellt werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  public copyOfferToProperty$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CopyOfferToProperty),
      switchMap(({ buyingOfferId, destinationPropertyId }) =>
        this._buyingOfferApiFacade.copyOfferToProperty$(buyingOfferId, destinationPropertyId).pipe(
          tap((result) => {
            if (!result) {
              this._snackbarService.showSnackbar(
                'Kaufanbot in anderes Objekt konnte nicht überträgt werden',
                'mat-warn',
                true
              );

              return;
            }

            this._snackbarService.showSnackbar(
              'Kaufanbot in anderes Objekt erfolgreich überträgt',
              'mat-primary',
              true
            );
          }),
        )
      )
    ),
    { dispatch: false }
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _snackbarService: SnackbarService,
    private readonly _buyingOfferApiFacade: BuyingOfferApiFacade,
    private readonly _documentDataFacade: DocumentsDataFacade,
    private readonly _translationService: TranslationService,
  ) { }
}
