import {
  PROPERTYTYPE,
  RentalDeposit,
  SinglePayment,
  TENANCYLAWAPPLICATION,
  UsageBoundCosts,
} from '@domains/rental-offer';
import { SigneeSignatureResult } from "@domains/handover-protocol";
import { CustomerType } from 'src/app/common-components/customer-type-selector/model';
import { BusinessCustomerWithCustomersDetails } from '@domains/business-customer';
import { CustomerWithContact } from '@domains/customer';
import { Object } from '@domains/object';
import { DocumentCreator } from 'src/app/common-models/document-creator';

export interface ExclusiveAgreement {
  id?: string;
  objectId: string;
  state: string;
  lastVisited: string;
  generalData: GeneralData;
  generalConditions: GeneralConditions;
  commission: Commission; // Germany only
  propertyDetails: PropertyDetailsForAgreements;
  salesSpecifications?: SalesSpecifications;
  rentalSpecifications?: RentalSpecifications;
  energyPass: EnergyPass;
  furtherConditions: FurtherConditions;
  dataProcessing: DataProcessing;
  additionalEAServices: AdditionalEAServices;
  contractEntityType: CustomerType;
  neededDocuments: Documents;
  contractEntities: ContractEntity[];
  businessContractEntity?: {
    customerId: string;
    customerData: BusinessCustomerWithCustomersDetails;
    ownership: string,
    ownershipProof: string,
  }
  contractEntityTrusteeship: ContractEntityTrusteeship;
  commissionBuy?: CommissionBuy;
  commissionRent?: CommissionRent;
  signatureData?: {
    signedDocumentUrl?: string;
  };
  object?: Object;
  creator?: DocumentCreator;
  expenseAllowance?: ExpenseAllowance;
  warrantInfo?: WarrantInfo;
}

export interface WarrantInfo {
  landRegisterWarrant: boolean;
  cadastreWarrant: boolean;
  dossierWarrant: boolean;
  propertyManagementWarrant: boolean;
  arrangementWarrant: boolean;
  decisionCollectionWarrant: boolean;
  reserveWarrant: boolean;
  annualStatementWarrant: boolean;
  bankWarrant: boolean;
  viewingWarrant: boolean;
  warningWarrant: boolean;
  notaryWarrant: boolean;
  commissionWarrant: boolean;
}

export interface ExpenseAllowance {
  expenseAllowance: boolean;
  expenseType: string;
  expenseAllowancePercentage: number;
  expenseAllowanceAmount: number;
  expenseAllowanceDescription: string;
}

export interface DataProcessing {
  generalAgreement: boolean;
  newsletterAgreed: boolean;
  questionaireAgreed: boolean;
  propupAgreed: boolean;
  headOrganisationAgreed: boolean;
}

export interface AdditionalEAServices {
  rentControlPackage: boolean;
  fairRentService?: FairRentService;
}

export interface Documents {
  landRegister: DOCSTATUS; // Grundbuchsauszug
  landZoning: DOCSTATUS; // Flächenwidmungsbestätigung
  developmentPlan: DOCSTATUS; // Bebauungsplan, Bebauungsvorschriften
  contaminatedSitesPlan: DOCSTATUS; // Auszug aus dem Altlastenatlas, bzw. Verdachtsflächenkataster, Gefahrenzonenplan
  cadastralMap: DOCSTATUS; // Auszug aus der (digitalen) Katastralmappe
  buildingPermit: DOCSTATUS; // Baubewilligung
  floorPlan: DOCSTATUS; // Grundriss, Lageplan
  permitOfUsePlan: DOCSTATUS; // Benützungsbewilligung/Fertigstellungsanzeige
  energyPass: DOCSTATUS; // Energieausweis
  condominiumContract: DOCSTATUS; // Wohnungseigentumsvertrag
  usefulValueAppraisal: DOCSTATUS; // Nutzwertgutachten
  advancePayment: DOCSTATUS; // Vorschreibung laufende Kosten d. Hausverwaltung
  annualStatement: DOCSTATUS;// Jahresabrechnung d. Hausverwaltung
  operatingCosts: DOCSTATUS; // Betriebskosten/Erhaltungs- und Verbesserungskosten-Vorausschau im Sinne des § 20 Abs. 2 WEG
  ownersMeetingProtocol: DOCSTATUS; // Protokoll der letzten Eigentümerversammlung
  supplyContracts: DOCSTATUS; // Versorgungsverträge (Strom, Wärme, Gas, Wasser, Kabelfernsehen, etc.)
  disposalContracts: DOCSTATUS; // Entsorgungsverträge (Kanal, Müll, etc.)
  insuranceContracts: DOCSTATUS; // Versicherungsverträge
  inventoryList: DOCSTATUS; // Inventarliste
  rentalContract: DOCSTATUS; // Urkunde über Anschaffungsvorgang (Kaufvertrag)
  landRegisterCharges: DOCSTATUS; // Urkunden zu grundbücherlichen Belastungen
  pictures: DOCSTATUS; // aktuelle Fotos von
  otherDocs: OtherDocs[];
}

export interface OtherDocs {
  description: string;
  docStatus: DOCSTATUS;
}

export enum DOCSTATUS {
  INACCURATE = 'INACCURATE',
  HANDEDOVER = 'HANDEDOVER',
  TOBEPROVIDED = 'TOBEPROVIDED',
}

export interface GeneralData {
  contract: CONTRACT; // Kaufvertrag oder Mietvertrag
  propertyUserType: string; // Immobilienart
}

export interface GeneralConditions {
  prolongationOption: boolean;
  validInMonths: number;
  contractStart: Date; //Startdatum AV
  contractValidUntil: Date; // Befristung AV
  contractNotes: string; // Anmerkungen zur Befristung
  isAwareOfCreditHistory: boolean; // Kennt Bonitätsauskunft
}

export interface Commission {
  commissionOption: string;
  commissionBase: number;
  commissionRentBase: number;
}

interface PropertyForExclusiveAgreement {
  use: string;
  constructionYear: number;
  constructionYearCa: boolean;
  cadastralCommunity: string;
  entryNumber: string;
  parcelNumber: string;
  parcelNumbers: string[];
  movingInDate: string;
  propertyType: PROPERTYTYPE.ALLEINEIGENTUM;
  propertyTypeNotes: string;
  shareInOwnership: string;
}

export interface PropertyDetailsForAgreements {
  property: PropertyForExclusiveAgreement;
  rulesOfUse: boolean;
  usageBoundCosts: UsageBoundCosts;
}

export interface SalesSpecifications {
  hasVATLiabilityOption: null | boolean;
  sellingPrice: null | number;
  applicationPrice: null | number;
  minPrice: null | number;
  bottomUpPrice: null | boolean;
  hasPriceOnRequest: null | boolean;
  hasPriceToBeDiscussed: null | boolean;
  includedInThePurchasePrice: null | string;
  misc: null | string;
  hasKnownHiddenDefects: null | false;
  hiddenDefects?: null | string;
  hasEntriesInLandRegister: null | false;
  entriesInLandRegister?: null | string;
}

interface Valorization {
  hasValorization: boolean;
  vpi: string | null;
}

interface Price {
  net: number | null;
  vat: number | null;
}

export enum Level {
  Minimum = 'level_1',
  Medium = 'level_2',
  Maximum = 'level_3',
}

interface FairRentService {
  isSelected: boolean;
  level: Level;
}

export interface CommissionBuy {
  commissionCalculationType: 'fixed' | 'percentage',
  commissonPercentage: number,
  commissionTransfer: boolean,
  commission: number,
  vat: number,
  additionalNotes: string,
}

export interface CommissionRent {
  commissionCalculationType: 'fixed' | 'rentBased',
  commissionMonths: number,
  commissionTotal: number,
  commissionPercentageSpecial: number,
  commission: number,
  vat: number,
  additionalNotes: string,
}

export interface RentalSpecifications {
  tenancyLaw: boolean;
  tenancyLawApplication: TENANCYLAWAPPLICATION | null;
  hasCommercialUseAllowance: boolean;
  hasSubLeasingAllowance: boolean;
  hasLeasingTerm: boolean;
  contractValidUntil: string;
  price: Price;
  valorization: Valorization;
  rentalDeposit: RentalDeposit;
  singlePayment: SinglePayment;
  misc: string;
}

export interface EnergyPass {
  energyPassAvailable: ENERGYPASSAVAILABLE;
  energyPassResponsible: ENERGYPASSRESPONSIBLE;
  energyPassDelivery: String;
}

export interface FurtherConditions {
  hasOwnership: boolean;
  hasExclusiveRight: boolean;
  specialAgreement: string;
  contractInOffice: boolean;
  faggExplained: boolean;
  faggAndCancelation: boolean;
  startBeforeCancelation: boolean;
}

export interface ContractEntityTrusteeship {
  trusteeship: boolean; // Treuhandschaft
  trusteeshipOwner: string; // Daten des Treuhänders
}

export interface ContractEntity extends CustomerWithContact {
  ownership: OWNERSHIP;
  ownershipProof: string;
}

// Common
export interface ExclusiveAgreementListItem {
  id: string;
  objectId: string;
  state: string;
  isCompleted: boolean;
  lastVisited: Date;
  username: string;
  contractEntitiesEmails: string[];
  signatureData?: {
    signedDocumentUrl?: string;
  };
  object: Object;
  creator?: DocumentCreator;
}

export interface ExclusiveAgreementEmailConfigurationData {
  id: string;
  objectId: string;
  contractEntitiesEmails: string[];
  variables: ExclusiveAgreementEmailVariables;
}

export interface ExclusiveAgreementSignatureCreation {
  objectId: string;
  exclusiveAgreementId: string;
  contractEntitiesSignatureData: SigneeSignatureResult[] | null;
}

export interface SignedAndCompletedExclusiveAgreement {
  id: string;
  objectId: string;
  state: string;
  lastVisited: Date;
  signatureData: {
    signedDocumentUrl: string;
  };
}

export enum OWNERSHIP {
  SELFOWNERSHIP = 'SELFOWNERSHIP',
  PARTIALOWNERSHIP = 'PARTIALOWNERSHIP',
  AUTHORIZEDOWNERSHIP = 'AUTHORIZEDOWNERSHIP',
}

export enum CONTRACT {
  BUYINGCONTRACT = 'BUYINGCONTRACT',
  RENTALCONTRACT = 'RENTALCONTRACT',
}

export enum ENERGYPASSAVAILABLE {
  ISAVAILABLE = 'ISAVAILABLE',
  NOTNECESSARY = 'NOTNECESSARY',
  NOTAVAILABLE = 'NOTAVAILABLE',
}

export enum ENERGYPASSRESPONSIBLE {
  CUSTOMER = 'CUSTOMER',
  AGENT = 'AGENT',
}

export interface ExclusiveAgreementEmailVariables {
  documentUrl: string;
  signature: string;
  propertyAddress: string;
}