import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, map, mergeMap, tap } from 'rxjs/operators';
import { EMPTY, throwError } from 'rxjs';

import * as DataActions from './actions';
import { getAgentCompany } from 'src/app/common-models/mapping';
import { HandoverProtocolApiFacade } from '../handover-protocol-api.facade';
import { HandoverProtocolDataFacade } from '../handover-protocol-data.facade';
import { SnackbarService } from 'services/snackbar.service';
import { AuthService } from 'services/auth.service';
import { DocumentService } from 'services/document.service';
import { environment } from 'src/environments/environment';
import { DocumentsDataFacade } from "../../documents";

@Injectable()
export class HandoverProtocolEffects {
  public loadHandoverProtocols$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadHandoverProtocols),
      switchMap(({ objectId }) =>
        this._hopApiFacade.loadHandoverProtocols$(objectId).pipe(
          switchMap((value) => [DataActions.HandoverProtocolsLoaded({ payload: value })]),
          catchError(() => [DataActions.HandoverProtocolsLoadingFailed()])
        )
      )
    )
  );

  public loadHandoverProtocolsByCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadHandoverProtocolsByCustomer),
      switchMap(({ customerId }) =>
        this._hopApiFacade.loadHandoverProtocolsByCustomer$(customerId).pipe(
          switchMap((value) => [DataActions.HandoverProtocolsLoaded({ payload: value })]),
          catchError(() => [DataActions.HandoverProtocolsLoadingFailed()])
        )
      )
    )
  );

  public loadHandoverProtocolDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadHandoverProtocolDetails),
      switchMap(({ objectId, hopId }) =>
        this._hopApiFacade.loadHandoverProtocolDetails$(objectId, hopId).pipe(
          switchMap((value) => [DataActions.HandoverProtocolDetailsLoaded({ payload: value })]),
          catchError(() => [DataActions.HandoverProtocolDetailsLoadingFailed()])
        )
      )
    )
  );

  public saveHandoverProtocol$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveHandoverProtocol),
      switchMap(({ handoverProtocol, isFinal, shouldPrint }) =>
        this._hopApiFacade.saveHandoverProtocol$(handoverProtocol).pipe(
          switchMap((hop) => {
            if (isFinal) {
              if (shouldPrint) {
                this._hopDataFacade.showHandoverProtocolDocument(hop.objectId, hop.id);
              }

              this._router.navigateByUrl(`/objects/${hop.objectId}/handover-protocols`);

              return [DataActions.SaveHandoverProtocolSucceded({ showToast: true })];
            }

            return [DataActions.SaveHandoverProtocolSucceded({ showToast: false })];
          }),
          catchError(() => [DataActions.SaveHandoverProtocolFailed({ showToast: isFinal ?? true })]),
        ))
    ),
  );

  deleteHandoverProtocol$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeleteHandoverProtocol),
      mergeMap(({ hopId, objectId }) =>
        this._hopApiFacade
          .deleteHandoverProtocol$(hopId)
          .pipe(
            switchMap(() => {
              this._snackbarService.showSnackbar(
                'Übergabeprotokoll erfolgreich gelöscht',
                'mat-primary',
                true
              );
              this._documentDataFacade.deleteHandoverProtocol(hopId)
              return [
                DataActions.DeleteHandoverProtocolSuccess({
                  hopId,
                }),
              ];
            }), catchError(() => {
              this._snackbarService.showSnackbar(
                'Übergabeprotokoll konnte nicht gelöscht werden',
                'mat-warn',
                true
              );
              return [DataActions.DeleteHandoverProtocolFailed({ hopId })];
            })
          )
      ),
    ),
  );

  public createAndUploadHandoverProtocolDocumentFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateAndUploadHandoverProtocolDocumentFailed),
      map(() =>
        this._snackbarService.showSnackbar(
          'Übergabeprotokoll PDF konnte nicht erzeugt werden',
          'mat-warn',
          true
        )
      )
    ),
    { dispatch: false }
  );

  public handoverProtocolCreationSucceded$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveHandoverProtocolSucceded),
      tap(({ showToast }) => {
        if (showToast) {
          this._snackbarService.showSnackbar(
            'Übergabeprotokoll erfolgreich erstellt',
            'mat-primary',
            true
          );
        }
      })
    ),
    { dispatch: false }
  );

  public handoverProtocolCreationFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveHandoverProtocolFailed),
      tap(({ showToast }) => {
        if (showToast) {
          this._snackbarService.showSnackbar(
            'Fehler beim Erstellen des Übergabeprotokolls',
            'mat-warn',
            true
          );
        }
      })
    ),
    { dispatch: false }
  );

  public trySignAndCompleteHandoverProtocol$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SignAndCompleteHandoverProtocol),
      mergeMap(({ handoverProtocolSignature }) => this._hopApiFacade.signAndCompleteHandoverProtocol$(
        handoverProtocolSignature.objectId,
        handoverProtocolSignature.handoverProtocolId,
        handoverProtocolSignature.transferorsSignatureData,
        handoverProtocolSignature.transfereesSignatureData,
        getAgentCompany(this._authService)
      ).pipe(
        tap((handoverProtocol) => {
          if (!environment.staticToggles.showNewHopScreen) {
            this._documentService.openDocumentLink(handoverProtocol.signatureData.signedDocumentUrl);
          }

          this._router.navigateByUrl(`/objects/${handoverProtocol.objectId}/handover-protocols/${handoverProtocol.id}/email`);
        }),
        catchError(() => {
          this._router.navigateByUrl(`/objects/${handoverProtocolSignature.objectId}/handover-protocols`);

          return EMPTY;
        }),
      )),
    ),
    { dispatch: false }
  );

  public draftSignHandoverProtocol$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DraftSignHandoverProtocol),
      mergeMap(({ handoverProtocolSignature, openEmailDialog }) =>
        this._hopApiFacade.signAndCompleteHandoverProtocol$(
          handoverProtocolSignature.objectId,
          handoverProtocolSignature.handoverProtocolId,
          handoverProtocolSignature.transferorsSignatureData,
          handoverProtocolSignature.transfereesSignatureData,
          getAgentCompany(this._authService)
        ).pipe(
          tap((handoverProtocol) => {
            if (openEmailDialog) {
              this._router.navigateByUrl(`/objects/${handoverProtocol.objectId}/handover-protocols/${handoverProtocol.id}/email`);
            }
          }),
          catchError(() => {
            this._router.navigateByUrl(`/objects/${handoverProtocolSignature.objectId}/handover-protocols`);

            return EMPTY;
          }),
        )),
    ),
    { dispatch: false }
  );


  public sendHandoverProtocolEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendHandoverProtocolEmail),
      switchMap(({ objectId, handoverProtocolId, emailData }) =>
        this._hopApiFacade.sendHandoverProtocolEmail$(objectId, handoverProtocolId, emailData).pipe(
          tap((success) => {
            if (success) {
              this._snackbarService.showSnackbar(
                'Übergabeprotokoll E-Mail erfolgreich versendet',
                'mat-primary',
                true
              );
            } else {
              throwError(new Error());
            }
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Übergabeprotokoll E-Mail konnte nicht versendet werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _documentService: DocumentService,
    private readonly _snackbarService: SnackbarService,
    private readonly _authService: AuthService,
    private readonly _hopApiFacade: HandoverProtocolApiFacade,
    private readonly _hopDataFacade: HandoverProtocolDataFacade,
    private readonly _documentDataFacade: DocumentsDataFacade
  ) { }
}
