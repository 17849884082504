import { createAction, props } from '@ngrx/store';
import {
  CadastralCommunity,
  Object,
  ObjectCreation,
  ObjectStatus,
  ObjectsCountByAgent,
  PropertyActivity,
  PropertySharing,
} from '../object.model';

export const LoadObjectDetails = createAction(
  '[Object] Load Object Details',
  props<{ objectId: string }>()
);
export const SyncObjectDetails = createAction(
  '[Object] Sync Object Details',
  props<{ objectId: string }>()
);
export const LinkEdirealObject = createAction(
  '[Object] Link Edireal Object',
  props<{ id: string, externalId: string }>()
);
export const ObjectDetailsLoaded = createAction(
  '[Object] Object Details Loaded',
  props<{ payload: Object }>()
);
export const ObjectDetailsLoadingFailed = createAction(
  '[Object] Object Details Loading Failed'
);

export const InitObjects = createAction('[Object] Init Objects');

export const AvailableAgentNamesLoaded = createAction(
  '[Object] Available Agent Names Loaded',
  props<{ payload: ObjectsCountByAgent[] }>()
);
export const AvailableAgentNamesLoadingFailed = createAction(
  '[Object] Available Agent Names Loading Failed'
);

export const LoadObjectsByAgent = createAction(
  '[Object] Load Objects By Agent',
  props<{ agentName: string }>(),
);
export const LoadObjectsByAgentFailed = createAction(
  '[Object] Failed to load Objects By Agent',
  props<{ agentName: string }>(),
);
export const LoadObjectsByAgentSucceeded = createAction(
  '[Object] Succeeded to load Objects',
  props<{ agentName: string; objects: Object[] }>(),
);

export const LoadExternalObjectDetails = createAction(
  '[Object] Load External Object Details',
  props<{
    objectId: string;
  }>()
);
export const ExternalObjectDetailsLoaded = createAction(
  '[Object] External Object Details Loaded',
  props<{ payload: Object }>()
);
export const ExternalObjectDetailsLoadingFailed = createAction(
  '[Object] External Object Details Loading Failed'
);
export const SaveProperty = createAction('[Object] Save Property', props<{ object: Object }>());

export const CreateObject = createAction(
  '[Object] Create Object',
  props<{ data: ObjectCreation }>()
);
export const CreateObjectFailed = createAction(
  '[Object] Failed to create Object'
);
export const CreateObjectSucceded = createAction(
  '[Object] Succeeded to create Object',
  props<{ payload: Object }>()
);
export const UpdateObject = createAction(
  '[Object] Update Object',
  props<{ data: Object }>()
);
export const UpdatePropertyPartially = createAction(
  '[Object] Update Property Partially',
  props<{ data: Partial<Object> }>()
);
export const UpdateObjectFailed = createAction(
  '[Object] Failed to update Object'
);
export const UpdateObjectSucceded = createAction(
  '[Object] Succeeded to update Object',
  props<{ payload: Object }>()
);
export const UpdateObjectStatus = createAction(
  '[Object] Update Object Status',
  props<{ objectId: string; objectStatus: ObjectStatus }>()
);
export const UpdateObjectStatusFailed = createAction(
  '[Object] Failed to update Object Status'
);
export const UpdateObjectStatusSucceded = createAction(
  '[Object] Succeeded to update Object Status',
  props<{ payload: Object }>()
);
export const LoadCadastralCommunities = createAction('[Cadastral Communities] Load Cadastral Communities');
export const LoadCadastralCommunitiesSucceeded = createAction('[Cadastral Communities] Load Cadastral Communities Succeeded',
  props<{ payload: CadastralCommunity[] }>()
);
export const LoadCadastralCommunitiesFailed = createAction('[Cadastral Communities] Load Cadastral Communities Failed');

export const SearchObjects = createAction(
  '[Object] Search Objects',
  props<{ searchText: string }>()
);
export const SearchObjectsFailed = createAction(
  '[Object] Failed to search Objects'
);
export const SearchObjectsSucceeded = createAction(
  '[Object] Succeeded to search Objects',
  props<{ payload: Record<string, Object[]> }>()
);
export const DeleteObjects = createAction(
  '[Object] Delete Objects',
  props<{ objectIds: string[] }>()
);
export const DeleteObjectsFailed = createAction(
  '[Object] Failed to delete Objects'
);
export const DeleteObjectsSucceeded = createAction(
  '[Object] Succeeded to delete Objects',
  props<{ payload: Object[] }>()
);

export const TriggerExternalObjectsLoadingSucceeded = createAction(
  '[Object] Succeeded to trigger external objects load'
);

export const TriggerExternalObjectLoadingFailed = createAction(
  '[Object] Failed to trigger external object load'
);

export const StartPropertiesDuplicationCheck = createAction(
  '[Object] Start Properties Duplication Check',
);
export const FinishPropertiesDuplicationCheck = createAction(
  '[Object] Finish Properties Duplication Check',
  props<{ payload: { hasUpdate: boolean } }>(),
);
export const ResetPropertiesDuplicationCheck = createAction(
  '[Object] Reset Properties Duplication Check',
);

export const LoadPropertySharingData = createAction(
  '[Object] Load Property Sharing Data',
  props<{ objectId: string }>()
);
export const PropertySharingDataLoaded = createAction(
  '[Object] Property Sharing Data Loaded',
  props<{ payload: PropertySharing[] }>()
);
export const PropertySharingDataLoadingFailed = createAction(
  '[Object] Property Sharing Data Failed'
);

export const DeletePropertySharing = createAction(
  '[Object] Delete Property Sharing',
  props<{ objectId: string, sharingId: string }>()
);
export const CreatePropertySharing = createAction(
  '[Object] Create Property Sharing',
  props<{ objectId: string, email: string }>()
);

export const LoadPropertyActivites = createAction(
  '[Object] Load Property Activities',
  props<{ objectId: string }>()
);
export const PropertyActivitiesLoaded = createAction(
  '[Object] Property Activities Loaded',
  props<{ payload: PropertyActivity[] }>()
);
export const PropertyActivitiesLoadingFailed = createAction(
  '[Object] Property Activities Loading Failed'
);
