import { Injectable } from "@angular/core";
import { CustomerWithContact } from "@domains/customer";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { catchError, first, tap } from "rxjs/operators";
import { AuthService } from "services/auth.service";
import { DocumentService } from "services/document.service";
import { getAgentCompany } from "src/app/common-models/mapping";
import { ExclusiveAgreementApiFacade } from "./exclusive-agreement-api.facade";
import { ExclusiveAgreement, ExclusiveAgreementSignatureCreation } from "./exclusive-agreement.model";
import { ExclusiveAgreementSelectors, ExclusiveAgreementFeatureState } from "./store";
import { ExclusiveAgreementActions } from "./store/action-types";
import { EmailPreviewBodyDto } from "@types";

@Injectable({ providedIn: 'root' })
export class ExclusiveAgreementDataFacade {
  exclusiveAgreements$ = this._store$.select(ExclusiveAgreementSelectors.exclusiveAgreements);
  exclusiveAgreementsLoading$ = this._store$.select(ExclusiveAgreementSelectors.exclusiveAgreementsLoading);
  exclusiveAgreementDetails$ = this._store$.select(ExclusiveAgreementSelectors.exclusiveAgreementDetails);
  exclusiveAgreementDetailsLoading$ = this._store$.select(ExclusiveAgreementSelectors.exclusiveAgreementDetailsLoading);

  constructor(
    private readonly _authService: AuthService,
    private readonly _docService: DocumentService,
    private readonly _exclusiveAgreementApiFacade: ExclusiveAgreementApiFacade,
    private readonly _store$: Store<ExclusiveAgreementFeatureState>
  ) { }

  public loadExclusiveAgreements(objectId: string): void {
    this._store$.dispatch(ExclusiveAgreementActions.LoadExclusiveAgreements({ objectId }));
  }

  public loadExclusiveAgreementsByCustomer(customerId: string): void {
    this._store$.dispatch(ExclusiveAgreementActions.LoadExclusiveAgreementsByCustomer({ customerId }));
  }

  public loadExclusiveAgreementDetails(objectId: string, exclusiveAgreementId: string): void {
    this._store$.dispatch(ExclusiveAgreementActions.LoadExclusiveAgreementDetails({ objectId, exclusiveAgreementId }));
  }

  public saveExclusiveAgreement(exclusiveAgreement: any, isFinal = false): void {
    this._store$.dispatch(ExclusiveAgreementActions.SaveExclusiveAgreement({ exclusiveAgreement, isFinal }));
  }

  public createExclusiveAgreement$(exclusiveAgreement: any): Observable<ExclusiveAgreement> {
    return this._exclusiveAgreementApiFacade.saveExclusiveAgreement$(exclusiveAgreement)
  }

  public deleteExclusiveAgreement(exclusiveAgreementId: string, objectId: string): void {
    this._store$.dispatch(ExclusiveAgreementActions.DeleteExclusiveAgreement({ exclusiveAgreementId, objectId }));
  }

  public getLastExclusiveAgreementDetails$(objectId: string): Observable<ExclusiveAgreement> {
    return this._exclusiveAgreementApiFacade.getLastExclusiveAgreementDetails$(objectId);
  }

  public getExclusiveAgreementDetails$(objectId: string, exclusiveAgreementId: string): Observable<ExclusiveAgreement> {
    return this._exclusiveAgreementApiFacade.loadExclusiveAgreementDetails$(objectId, exclusiveAgreementId);
  }

  public compressFiles$(objectId: string, exclusiveAgreementId: string, filePaths: string[], fileNames: string[]): Observable<string> {
    return this._exclusiveAgreementApiFacade.compressFiles$(objectId, exclusiveAgreementId, filePaths, fileNames);
  }

  public updateExclusiveAgreementCustomers(
    contractEntities: CustomerWithContact[],
    exclusiveAgreementId: string,
    objectId: string
  ): void {
    this._store$.dispatch(ExclusiveAgreementActions.UpdateExclusiveAgreementCustomers({
      contractEntities,
      exclusiveAgreementId,
      objectId,
    }));
  }

  public getExclusiveAgreementEmailVariables$(exclusiveAgreementId: string, documentUrl: string): Observable<any> {
    return this._exclusiveAgreementApiFacade.getExclusiveAgreementEmailVariables$(exclusiveAgreementId, documentUrl);
  }

  public createExclusiveAgreementDocument$(objectId: string, exclusiveAgreementId: string): Observable<string> {
    const agentCompany = getAgentCompany(this._authService);
    return this._exclusiveAgreementApiFacade.createExclusiveAgreementDocument$(objectId, exclusiveAgreementId, agentCompany);
  }

  public failedCreateAndUploadExclusiveAgreementDocument(): void {
    this._store$.dispatch(ExclusiveAgreementActions.CreateAndUploadExclusiveAgreementDocumentFailed());
  }

  public showExclusiveAgreementDocument(objectId: string, exclusiveAgreementId: string): void {
    this.createExclusiveAgreementDocument$(objectId, exclusiveAgreementId).pipe(
      first(),
      tap((url) => {
        this._docService.openDocumentLink(url);
      }),
      catchError((err) => {
        this.failedCreateAndUploadExclusiveAgreementDocument();
        throw err;
      })
    ).subscribe()
  }

  public sendExclusiveAgreementEmail( exclusiveAgreementId: string, emailData: any): void {
    const agentCompany = getAgentCompany(this._authService);
    this._store$.dispatch(ExclusiveAgreementActions.SendExclusiveAgreementEmail({
      exclusiveAgreementId,
      emailData,
      agentCompany,
    }));
  }

  public signAndCompleteExclusiveAgreement(exclusiveAgreementSignature: ExclusiveAgreementSignatureCreation): void {
    this._store$.dispatch(ExclusiveAgreementActions.SignAndCompleteExclusiveAgreement({ exclusiveAgreementSignature }));
  }

}
