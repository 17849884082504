import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap, tap,
} from 'rxjs/operators';

import { SnackbarService } from 'services/snackbar.service';
import { PlainAgreementApiFacade } from '../plain-agreement-api.facade';
import * as DataActions from './actions';
import { getAgentCompany } from "../../../common-models/mapping";
import { AuthService } from "services/auth.service";
import { EMPTY } from 'rxjs';
import { DocumentsDataFacade } from "../../documents";
import { ContractEntity } from "../../exclusive-agreement";

@Injectable()
export class PlainAgreementEffects {
  public loadPlainAgreements$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadPlainAgreements),
      switchMap(({ objectId }) =>
        this._plainAgreementApiFacade
          .loadPlainAgreement$(objectId)
          .pipe(
            switchMap((value) => [
              DataActions.PlainAgreementsLoaded({ payload: value }),
            ]),
            catchError(() => [
              DataActions.PlainAgreementDetailsLoadingFailed(),
            ])
          )
      )
    )
  );

  public loadPlainAgreementsByCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadPlainAgreementsByCustomer),
      switchMap(({ customerId }) =>
        this._plainAgreementApiFacade.loadPlainAgreementsByCustomer$(customerId).pipe(
          switchMap((value) => [DataActions.PlainAgreementsLoaded({ payload: value })]),
          catchError(() => [DataActions.PlainAgreementsLoadingFailed()])
        )
      )
    )
  );

  public loadPlainAgreementDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadPlainAgreementDetails),
      switchMap(({ objectId, plainAgreementId }) =>
        this._plainAgreementApiFacade
          .loadPlainAgreementDetails$(objectId, plainAgreementId)
          .pipe(
            switchMap((value) => [
              DataActions.PlainAgreementDetailsLoaded({ payload: value }),
            ]),
            catchError(() => [
              DataActions.PlainAgreementDetailsLoadingFailed(),
            ])
          )
      )
    )
  );

  public savePlainAgreement$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SavePlainAgreement),
      switchMap(({ plainAgreement, isFinal }) => {
        const contractEntitiesWithoutCustomers =
          plainAgreement.contractEntities.map(
            (contractEntity: ContractEntity) => {
              const {
                id,
                title,
                firstname,
                lastname,
                birthdate,
                contact,
                nationality,
                pep,
                ...contractEntitiesWithoutCustomers
              } = contractEntity;
              return {
                ...contractEntitiesWithoutCustomers,
                customerId: (contractEntity as any).customerId,
              };
            }
          );

        const {
          signatureData,
          ...plainAgreementWithoutSignature
        } = plainAgreement;

        const plainAgreementWithoutCustomers = {
          ...plainAgreementWithoutSignature,
          contractEntities: contractEntitiesWithoutCustomers,
        };

        return this._plainAgreementApiFacade
          .savePlainAgreement$(plainAgreementWithoutCustomers)
          .pipe(
            switchMap((ea) => {
              if (isFinal) {
                this._router.navigateByUrl(`/objects/${ea.objectId}/object-recordings`);

                return [DataActions.CreatePlainAgreementSucceded()];
              }
              return [];
            })
          );
      })
    )
  );

  public plainAgreementCreationSucceded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.CreatePlainAgreementSucceded),
        map(() =>
          this._snackbarService.showSnackbar(
            'Schlichter Maklervertrag erfolgreich erstellt',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public updatePlainAgreementCustomers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.UpdatePlainAgreementCustomers),
      switchMap(({ objectId, plainAgreementId, contractEntities }) =>
        this._plainAgreementApiFacade
          .loadPlainAgreementDetails$(objectId, plainAgreementId)
          .pipe(
            switchMap((plainAgreement) => {
              plainAgreement.contractEntities.map((c, index) => ({
                ...c,
                customerId: contractEntities[index]?.id,
              }));
              plainAgreement.contractEntities =
                plainAgreement.contractEntities.map((c, index) => ({
                  ...c,
                  customerId: contractEntities[index]?.id,
                }));

              return [
                DataActions.SavePlainAgreement({
                  plainAgreement,
                  isFinal: true,
                }),
              ];
            })
          )
      )
    )
  );

  deletePlainAgreement$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeletePlainAgreement),
      mergeMap(({ plainAgreementId, objectId }) =>
        this._plainAgreementApiFacade
          .deletePlainAgreement$(plainAgreementId)
          .pipe(
            switchMap(() => {
              this._snackbarService.showSnackbar(
                'Schlichter Maklervertrag erfolgreich gelöscht',
                'mat-primary',
                true
              );
              this._documentDataFacade.deletePlainAgreement(plainAgreementId)
              return [
                DataActions.DeletePlainAgreementSuccess({
                  plainAgreementId,
                }),
              ];
            }),
            catchError(() => {
              this._snackbarService.showSnackbar(
                'Schlichter Maklervertrag konnte nicht gelöscht werden',
                'mat-warn',
                true
              );
              return [
                DataActions.DeletePlainAgreementFailed({
                  plainAgreementId,
                }),
              ];
            })
          )
      )
    )
  );

  public createAndUploadPlainAgreementDocumentFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateAndUploadPlainAgreementDocumentFailed),
      map(() =>
        this._snackbarService.showSnackbar(
          'Schlichter Maklervertrag PDF konnte nicht erzeugt werden',
          'mat-warn',
          true
        )
      )
    ),
    { dispatch: false }
  );

  public trySignAndCompletePlainAgreement$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SignAndCompletePlainAgreement),
      mergeMap(({ plainAgreementSignature }) =>
        this._plainAgreementApiFacade.signAndCompletePlainAgreement$(
          plainAgreementSignature.objectId,
          plainAgreementSignature.plainAgreementId,
          plainAgreementSignature.contractEntitiesSignatureData,
          getAgentCompany(this._authService)
        ).pipe(
          tap((plainAgreement) => {
            this._router.navigateByUrl(
              `/objects/${plainAgreement.objectId}/object-recordings/plain-agreement/${plainAgreement.id}/email`
            );
          }),
          catchError(() => {
            this._router.navigateByUrl(`/objects/${plainAgreementSignature.objectId}/object-recordings/plain-agreement`);

            return EMPTY;
          })
        )),
    ),
    { dispatch: false }
  );

  public sendPlainAgreementEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendPlainAgreementEmail),
      switchMap(({ objectId, plainAgreementId, emailData, agentCompany }) =>
        this._plainAgreementApiFacade.sendPlainAgreementEmail$(
          objectId,
          plainAgreementId,
          emailData,
          agentCompany
        ).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Schlichter Maklervertrag erfolgreich gesendet',
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Schlichter Maklervertrag E-Mail konnte nicht gesendet werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _snackbarService: SnackbarService,
    private readonly _plainAgreementApiFacade: PlainAgreementApiFacade,
    private readonly _documentDataFacade: DocumentsDataFacade,
  ) { }
}
