<mat-sidenav-container autosize class="w-full">
  <mat-sidenav #leftSidenav [mode]="isMobile ? 'over' : 'side'" [(opened)]="isLeftSidenavVisible"
    [fixedInViewport]="true">
    <nav #leftSidenavContent
      class="flex flex-col bg-primary-dark text-white text-lg items-start min-h-full pl-3 pr-2 gap-y-8 w-screen lg:w-64">
      <div class="flex justify-between w-full items-center xl:pb-4 2xl:pb-8">
        <a routerLink="/dashboard" (click)="onMenuItemClicked()">
          <img class="block pt-4 lg:pt-8 ml-4" style="width: 134px" src="/assets/images/propup_lettering.png"
            alt="PROPUP Logo" />
        </a>
        <button (click)="onMenuItemClicked()" *ngIf="isLeftSidenavButtonVisible" ngClass="is-active" mat-icon-button
          class="block lg:hidden" type="button">
          <mat-icon class="text-3xl">close</mat-icon>
        </button>
      </div>
      <app-sidenav-button class="hover:border-2 hover:border-white p-4 rounded-xl border-2 border-transparent w-full"
        (click)="onMenuItemClicked()" imageSource="/assets/icons/sidenav/start.svg" route="dashboard"
        [text]="('menu.my_properties' | translate)">
      </app-sidenav-button>
      <app-sidenav-button class="hover:border-2 hover:border-white p-4 rounded-xl border-2 border-transparent w-full"
        *ngIf="(hasFreeVersion$ | async) === false" (click)="onMenuItemClicked()"
        imageSource="/assets/icons/sidenav/customers.svg" route="customers" [text]="('menu.my_contacts' | translate)">
      </app-sidenav-button>
      <a [matTooltip]="('alerts.full_version' | translate)" *ngIf="hasFreeVersion$ | async"
        class="flex items-center gap-x-4 lg:gap-x-2 text-gray-500 p-4 hover:border-2 hover:border-white p-4 rounded-xl border-2 border-transparent w-full">
        <div class="inline-block self-center lg:block">{{ 'menu.my_contacts' | translate}}</div>
      </a>

      <app-sidenav-button class="hover:border-2 hover:border-white p-4 rounded-xl border-2 border-transparent w-full"
        (click)="onMenuItemClicked()" imageSource="/assets/icons/sidenav/documents.svg" route="documents"
        [text]="('menu.my_documents' | translate)">
      </app-sidenav-button>
      <app-sidenav-button *ngIf="feature.showOfficeArea"
        class="hover:border-2 hover:border-white p-4 rounded-xl border-2 border-transparent w-full"
        (click)="onMenuItemClicked()" imageSource="/assets/icons/sidenav/office-area.svg" route="office-area"
        [text]="('menu.my_company' | translate)">
      </app-sidenav-button>
      <app-sidenav-button class="hover:border-2 hover:border-white p-4 rounded-xl border-2 border-transparent w-full"
        (click)="onMenuItemClicked()" *ngIf="feature.showMarketplacePage"
        imageSource="/assets/icons/sidenav/marketplace.svg" route="marketplace" text="Services">
      </app-sidenav-button>
      <app-sidenav-button class="hover:border-2 hover:border-white p-4 rounded-xl border-2 border-transparent w-full"
        (click)="onMenuItemClicked()" imageSource="/assets/icons/sidenav/settings.svg" route="settings"
        [text]="('menu.settings' | translate)">
      </app-sidenav-button>
      <a href="https://www.propup.at/hilfe" target="_blank"
        class="flex items-center gap-x-4 lg:gap-x-2 hover:border-2 hover:border-white p-4 rounded-xl border-2 border-transparent w-full"
        style="filter: opacity(70%)">
        <img src="/assets/icons/sidenav/help.svg" alt="Help" class="block w-8" />
        <div class="inline-block self-center text-xl lg:block">{{ 'menu.help_new' | translate}}</div>
      </a>
      <app-sidenav-button class="hover:border-2 hover:border-white p-4 rounded-xl border-2 border-transparent w-full"
        (click)="onLogoutClicked()" imageSource="/assets/icons/sidenav/logout.svg" [text]="('menu.logout' | translate)">
      </app-sidenav-button>
    </nav>
  </mat-sidenav>
  <mat-sidenav #rightSidenav position="end" [mode]="isMobile ? 'over' : 'side'" [(opened)]="isRightSidenavVisible"
    [fixedInViewport]="true">
    <div #rightSidenavContent>
      <right-sidebar *ngIf="isLoggedIn" [isMobile]="isMobile" [isExpanded]="isRightSidenavExpanded"></right-sidebar>
    </div>
  </mat-sidenav>


  <mat-sidenav-content>
    <nav
      class="bg-gray-light w-full h-24 flex justify-between items-center fixed z-50 flex items-center lg:hidden pl-6 pr-4"
      *ngIf="isLoggedIn">
      <button (click)="showLeftSideNav()" *ngIf="isLeftSidenavButtonVisible" mat-icon-button class="block lg:hidden"
        type="button">
        <mat-icon class="text-3xl">menu</mat-icon>
      </button>

      <button (click)="showRightSideNav()" *ngIf="isLeftSidenavButtonVisible" mat-icon-button
        class="flex items-center lg:hidden" type="button">
        <common-user-avatar size="mid"></common-user-avatar>
        <mat-icon class="ml-2 text-xl">keyboard_arrow_down</mat-icon>
      </button>
    </nav>

    <main class="flex min-h-screen min-w-full relative" [ngClass]="{ 'pt-24': isLeftSidenavButtonVisible }">
      <div class="flex-grow">
        <app-banner *ngIf="feature.showBanner && isMobile && isLoggedIn"></app-banner>
        <app-teams-banner *ngIf="isMobile && isLoggedIn"></app-teams-banner>

        <div *ngIf="isLoggedIn && isLeftSidenavVisible && !isMobile" class="p-4">
          <app-banner *ngIf="feature.showBanner"></app-banner>
          <app-teams-banner></app-teams-banner>
        </div>

        <common-screen-size-indicator *ngIf="feature.indicateScreenSize"></common-screen-size-indicator>
        <div [ngStyle]="{width:contentWidth}">
          <router-outlet></router-outlet>
        </div>
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
