import { CustomerWithContact } from '@domains/customer';
import { createAction, props } from '@ngrx/store';
import {ExclusiveAgreement, ExclusiveAgreementSignatureCreation} from '../exclusive-agreement.model';
import {AgentCompany} from "../../../common-models/agent-company";

export const LoadExclusiveAgreements = createAction('[ExclusiveAgreement] Load Exclusive Agreements', props<{ objectId: string }>());
export const LoadExclusiveAgreementsByCustomer = createAction('[ExclusiveAgreement] Load Exclusive Agreements By Customer', props<{ customerId: string}>());
export const ExclusiveAgreementsLoaded = createAction('[ExclusiveAgreement] Exclusive Agreements Loaded', props<{ payload: ExclusiveAgreement[] }>());
export const ExclusiveAgreementsLoadingFailed = createAction('[ExclusiveAgreement] Exclusive Agreements Loading Failed');

export const LoadExclusiveAgreementDetails = createAction('[ExclusiveAgreement] Load Exclusive Agreement Details', props<{
  objectId: string;
  exclusiveAgreementId: string;
}>());
export const ExclusiveAgreementDetailsLoaded = createAction('[ExclusiveAgreement] Exclusive Agreement Details Loaded', props<{ payload: ExclusiveAgreement }>());
export const ExclusiveAgreementDetailsLoadingFailed = createAction('[ExclusiveAgreement] Exclusive Agreement Details Loading Failed');

export const SaveExclusiveAgreement = createAction('[ExclusiveAgreement] Save Exclusive Agreement', props<{ exclusiveAgreement: ExclusiveAgreement; isFinal?: boolean }>());

export const CreateExclusiveAgreementSucceded = createAction('[ExclusiveAgreement] Succeeded to create and save Exclusive Agreement');

export const DeleteExclusiveAgreement = createAction('[ExclusiveAgreement] Delete Exclusive Agreement', props<{ exclusiveAgreementId: string, objectId: string }>());
export const DeleteExclusiveAgreementSuccess = createAction('[ExclusiveAgreement] Delete Exclusive Agreement Succeeded', props<{ exclusiveAgreementId: string }>());
export const DeleteExclusiveAgreementFailed = createAction('[ExclusiveAgreement] Delete Exclusive Agreement Failed', props<{ exclusiveAgreementId: string }>());

export const UpdateExclusiveAgreementCustomers = createAction(
  '[ExclusiveAgreement] Update Exclusive Agreement Customers',
  props<{
    contractEntities: CustomerWithContact[];
    exclusiveAgreementId: string;
    objectId: string;
  }>()
);

export const CreateAndUploadExclusiveAgreementDocumentFailed = createAction('[ExclusiveAgreement] Failed to create and upload Exclusive Agreement document');

export const SendExclusiveAgreementEmail = createAction(
  '[ExclusiveAgreement] Send Exclusive Agreement Email',
  props<{
    exclusiveAgreementId: string;
    emailData: any;
    agentCompany: AgentCompany;
  }>()
);

export const SignAndCompleteExclusiveAgreement = createAction(
  '[ExclusiveAgreement] Sign and Complete Exclusive Agreement',
  props<{ exclusiveAgreementSignature: ExclusiveAgreementSignatureCreation}>()
);
