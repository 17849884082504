import { Injectable } from '@angular/core';
import { Apollo, gql } from "apollo-angular";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { SnackbarService } from 'services/snackbar.service';

@Injectable({ providedIn: 'root' })
export class RemaxService {

  constructor(private apollo: Apollo, private _snackbar: SnackbarService) { }

  checkTokenValidity$(): Observable<boolean> {
    return this.apollo.query({
      query: gql`
        query RemaxTokenValidity {
          remaxTokenValidity
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }).pipe(map((response: any) => response.data.remaxTokenValidity));
  }

  getRemaxAgentData$(): Observable<string> {
    return this.apollo.query({
      query: gql`
        query RemaxAgentData {
          remaxAgentData
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }).pipe(map((response: any) => response.data.remaxAgentData));
  }

  triggerObjectsSync$(): Observable<boolean> {
    console.log('triggerObjectsSync$');
    return this.apollo
      .mutate({
        mutation: gql`
          mutation TriggerRemaxObjectsSync {
            triggerRemaxObjectsSync
          }
        `,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      })
      .pipe(
        map((response: any) => response.data.triggerRemaxObjectsSync),
        tap((isSuccessful: boolean) => {
          if (isSuccessful) {
            this._snackbar.showSnackbar(
              'Objektliste erfolgreich aktualisiert',
              'mat-primary',
              true
            );

            return;
          }

          this._snackbar.showSnackbar(
            'Objektliste konnte nicht aktualisiert werden',
            'mat-warn',
            true,
          );
        })
      );
  }
}
