import { Injectable } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'services/auth.service';
import { ViewingPass } from './viewing-pass.model';
import { amountHigherThanObjectPrice } from 'src/app/viewing-pass/viewing-pass-details/steps/fee-calculation-buy-page/objectprice-amount.cross-validator';
import { CustomerType } from 'src/app/common-components/customer-type-selector/model';
import { BusinessCustomerFormService } from '@domains/business-customer';
import { CustomerFormService, CustomerWithContact } from '@domains/customer';
import {RoleProviderService} from "services/role-provider.service";

@Injectable({ providedIn: 'root' })
export class ViewingPassFormService {
  constructor(
    private readonly _customerFormService: CustomerFormService,
    private readonly _businessCustomerFormService: BusinessCustomerFormService,
    private readonly _fb: FormBuilder,
    private readonly _authService: AuthService,
    private _roleProviderService: RoleProviderService,
  ) {
  }

  hasCustomerPortalEnabled(): boolean {
    if (this._authService.isRemax) {
      if (!this._roleProviderService.hasRole('propup-special-offer')) {
        return true
      }
    }
    return false;
  }

  public createEmailsArrayItemControl(email?: string): FormControl {
    return this._fb.control(email ?? '');
  }

  public createCustomersArrayItemForm(): FormGroup {
    return this._customerFormService.createCustomerFormGroup();
  }

  public convertCustomerToFormGroup(c: CustomerWithContact): FormGroup {
    return this._customerFormService.convertToFormGroup(c);
  }

  public createBusinessCustomersArrayItemForm(): FormGroup {
    return this._businessCustomerFormService.createPrivateCustomer();
  }

  public createDocumentNotesFormGroup(): FormGroup {
    return this._fb.group({
      sellingLead: false,
      financingStatus: false,
      financingOffer: this.createFinancingOfferFormGroup(),
      notes: '',
      viewingDate: [new Date().toISOString().substring(0, 10)],
      dave: false,
      creditCheck: false,
      sendCustomerPortalLink: this.hasCustomerPortalEnabled(),
    })
  }

  public createFinancingOfferFormGroup(): FormGroup {
    return this._fb.group({
      agentOffer: false,
      fimobilia: false,
      realfinanz: false,
      novius: false,
      financingContractPartnerId: '',
    })
  }

  public createDocumentsArrayItemForm(): FormGroup {
    return this._fb.group({
      id: '',
      description: '',
      type: '',
      storageUrl: '',
      title: '',
      documentFrom: new Date().toISOString().substring(0, 10),
      state: ''
    });
  }

  public createViewingPassForm(isRentObject: boolean = false): FormGroup {
    const form = this._fb.group({
      state: ['pending'],
      vp_type: 'full',
      objectId: [''],
      customerType: [CustomerType.Private],
      businessCustomer: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
      }),
      customers: this._fb.array([]),
      legalInformation: this._fb.group({
        dataProcessing: this._createDataProcessingFormGroup(),
        detailInformation: this._fb.group({
          agentRepresentsBothParties: [true],
          agentHasSellerRelationship: [false],
          instructedCancellationConditions: [false, Validators.requiredTrue], //Disable
          cancellationNotification: [false],
          signedInAgentOffice: [false],
          agentCanTakeActionBeforeCancellationPeriod: [true],
        }),
        cancellationPeriod: this._fb.group({
          approvedAgentCanTakeActionBeforeCancellationPeriod: [true],
        }),
        feeAtFailedIntermediation: this._fb.group({
          approvedFeeAtFailedIntermediation: true,
        }),
        emails: this._fb.array([]),
      }),
      documentNotes: this.createDocumentNotesFormGroup(),
      documents: this._fb.array([]),
    });

    const feeCalculationFormGroup = this._createFeeCalculationFormGroup(isRentObject);
    const legalInformationGroup = form.get('legalInformation') as FormGroup;
    legalInformationGroup.addControl('feeCalculation', feeCalculationFormGroup);

    form.get('customerType')?.valueChanges.subscribe((value) => {
      if (value === CustomerType.Private) {
        form.get('businessCustomer')?.disable();
        form.get('customers')?.enable();
      } else if (value === CustomerType.Business) {
        form.get('businessCustomer')?.enable();
        form.get('customers')?.disable();
      }
    });

    form.get('vp_type')?.valueChanges.subscribe((value) => {
      if (value === "activityPass") {
        form.get('legalInformation.detailInformation')?.disable();
        form.get('legalInformation.feeCalculation.feeBasisPerMonth')?.disable();
        form.get('legalInformation.feeCalculation.monthsToPayFeeBasis')?.disable();
        form.get('legalInformation.feeCalculation.amount')?.disable();
      } else {
        form.get('legalInformation.detailInformation')?.enable();
        form.get('legalInformation.feeCalculation.feeBasisPerMonth')?.enable();
        form.get('legalInformation.feeCalculation.monthsToPayFeeBasis')?.enable();
        form.get('legalInformation.feeCalculation.amount')?.enable();
      }
    });

    return form;
  }

  public createViewingPassInitialValue(
    isRentObjects: boolean,
    data: { objectId: string; price: number; defaultEmails: string[] },
  ): ViewingPass {
    const viewingPassForm = this.createViewingPassForm(isRentObjects);

    const emails = data.defaultEmails ?? [];

    const formValue = viewingPassForm.value;

    return {
      ...formValue,
      objectId: data.objectId,
      id: '',
      state: 'pending',
      customerType: CustomerType.Private,
      creationDate: '',
      lastVisited: '',
      legalInformation: {
        ...formValue.legalInformation,
        feeCalculation: {
          ...formValue.legalInformation.feeCalculation,
          objectPrice: data.price,
        },
        emails,
      },
    };
  }

  private _createDataProcessingFormGroup(): FormGroup {
    const isRemax = this._authService.isRemax;

    if (isRemax) {
      return this._fb.group({
        commissionedAgent: [true],
        agreedToNewsletter: [true],
        agreedToSurvey: [true],
        agreedToDataTransmissionToFranchise: [true, Validators.requiredTrue],
      });
    }

    return this._fb.group({
      commissionedAgent: [true],
      agreedToNewsletter: [true],
      agreedToSurvey: [true],
    });
  }

  private _createFeeCalculationFormGroup(isRentObject: boolean = false): FormGroup {
    if (isRentObject) {
      return this._fb.group({
        objectPrice: [0, [Validators.min(0)]],
        feeCalculationType: ['calculated'],
        feeBasisPerMonth: [0, [Validators.required, Validators.min(0)]], //Disable
        monthsToPayFeeBasis: [0, [Validators.required, Validators.min(0)]], //Disable
        amount: [0, [Validators.required, Validators.min(0)]], //Disable
        feeAtFailedIntermediation: [true],
      });
    }

    return this._fb.group({
      objectPrice: [0, [Validators.required, Validators.min(0)]],
      feeCalculationType: ['percentage'],
      amount: [0, [Validators.required, Validators.min(0)]],
      percentage: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      feeAtFailedIntermediation: [true],
    }, { validators: amountHigherThanObjectPrice });
  }
}
