import { Component } from '@angular/core';

@Component({
  selector: 'app-banner',
  template: `
    <div class="flex justify-between rounded-lg banner-container p-2 pl-4">
      <div class="flex items-center">
        <div class="img-container">

        </div>
        <div class="text-content text-primary">
          {{ 'banner.text' | translate}}
        </div>
      </div>
      <div class="flex items-center ml-2">
        <a href={{customerPortal.link}} target="_blank"
           class="text-base font-source font-bold py-2 bg-primary text-white rounded-lg px-3 text-center"
        >{{ 'banner.buttonText' | translate}}</a>
      </div>
    </div>
  `,
  styles: [`
    .banner-container {
      background-color: #EEF3FA;
    }
  `]
})
export class BannerComponent {

  constructor( ) { }

  customerPortal = {
    link: 'https://www.youtube.com/playlist?list=PLwA19okU0p8lQ9OZF64u8zuQvJJviSO_l',
    text: 'Kennst du schon unsere kurzen Erklärvideos?',
    buttonText: 'Zu den Videos'
  }


}
