import { Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakLoginOptions, KeycloakProfile } from 'keycloak-js';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isLoggedIn = false;
  public profile!: KeycloakProfile;
  public picture$ = new ReplaySubject<string | undefined>(1);
  public companyLogo$ = new ReplaySubject<string | undefined>(1);

  private _attributes!: Record<string, [string]> | undefined;

  get id(): string | undefined {
    return this.profile?.id;
  }

  get name(): string | undefined {
    return this.profile?.username;
  }

  get email(): string | undefined {
    return this.profile?.email;
  }

  get firstName(): string | undefined {
    return this.profile?.firstName;
  }

  get lastName(): string | undefined {
    return this.profile?.lastName;
  }

  get fullName(): string | undefined {
    return `${this.firstName ?? ''} ${this.lastName ?? ''}`.trim();
  }

  get companyLogo(): string | undefined {
    return this._attributes?.company_logo && this._attributes.company_logo[0];
  }

  get isDeveloper(): boolean {
    return (
      this.profile?.email?.endsWith('@squer.at') ||
      this.profile?.email?.endsWith('@propup.at') ||
      false
    );
  }

  get roles(): string[] {
    return this._keycloak.getUserRoles();
  }

  get picture(): string | undefined {
    return this._attributes?.picture && this._attributes.picture[0];
  }

  get isRemax(): boolean {
    const attrIsRemax =
      this._attributes?.isRemax && this._attributes.isRemax[0];
    return attrIsRemax === 'true';
  }

  get themeConfig(): string | undefined {
    return this._attributes?.themeConfig && this._attributes.themeConfig[0];
  }

  get legalEntity(): string | undefined {
    return (
      this._attributes?.lk_legal_entity && this._attributes.lk_legal_entity[0]
    );
  }

  get officeName(): string | undefined {
    return (
      this._attributes?.lk_office_name && this._attributes.lk_office_name[0]
    );
  }

  get officeAddress(): string | undefined {
    return (
      this._attributes?.address_formatted &&
      this._attributes.address_formatted[0]
    );
  }

  get phone(): string | undefined {
    return this._attributes?.phone_number && this._attributes.phone_number[0];
  }

  get authenticated(): boolean {
    return this._keycloak.getKeycloakInstance().authenticated ?? false;
  }

  get accessToken(): string {
    return this._keycloak.getKeycloakInstance()?.token ?? '';
  }

  get sessionState(): string {
    return (
      this._keycloak.getKeycloakInstance()?.tokenParsed?.session_state ?? ''
    );
  }

  constructor(private readonly _keycloak: KeycloakService) {
    this._keycloak.keycloakEvents$.subscribe((e) => {
      if (e.type === KeycloakEventType.OnAuthSuccess) {
        this.isLoggedIn = true;

        this._keycloak.loadUserProfile().then((profile) => {
          this.profile = profile;

          this._attributes = this._getProfileAttributes();
          this.updatePicture();
          this.updateCompanyLogo();
        });
      }
      if (e.type === KeycloakEventType.OnTokenExpired) {
        // Update 30 seconds before token expiration
        this._keycloak.updateToken(30);
      }
    });
  }

  login(): void {
    const options: KeycloakLoginOptions = {
      redirectUri: window.location.origin + '/post-login',
    };
    this._keycloak.login(options);
  }

  onOfficeLogin(redirectUrl: string): void {
    const options: KeycloakLoginOptions = {
      redirectUri: redirectUrl,
    };

    this._keycloak.login(options);
  }

  logout(): void {
    const logoutBaseUrl = this._keycloak.getKeycloakInstance().createLogoutUrl();
    this._keycloak.logout(logoutBaseUrl);
  }

  updatePicture(url?: string): void {
    this.picture$.next(url ?? this.picture);
  }

  updateCompanyLogo(url?: string): void {
    this.companyLogo$.next(url ?? this.companyLogo);
  }

  private _getProfileAttributes(): Record<string, [string]> | undefined {
    return (this.profile as any)?.attributes;
  }
}
