import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CreateCustomerPortal } from './customer-portal.model';
import { CustomerType } from 'src/app/common-components/customer-type-selector/model';
import { BusinessCustomerFormService } from '@domains/business-customer';
import { CustomerFormService, CustomerWithContact } from '@domains/customer';
import { ObjectDataFacade } from "@domains/object";

@Injectable({ providedIn: 'root' })
export class CustomerPortalFormService {
  object$ = this._objectDataFacade.objectDetails$;

  constructor(
    private readonly _customerFormService: CustomerFormService,
    private readonly _businessCustomerFormService: BusinessCustomerFormService,
    private readonly _fb: FormBuilder,
    private readonly _objectDataFacade: ObjectDataFacade,
  ) { }

  public createEmailsArrayItemControl(email?: string): FormControl {
    return this._fb.control(email ?? '');
  }

  public createCustomersArrayItemForm(): FormGroup {
    return this._customerFormService.createCustomerFormGroup();
  }

  public convertCustomerToFormGroup(c: CustomerWithContact): FormGroup {
    return this._customerFormService.convertToFormGroup(c);
  }

  public createBusinessCustomersArrayItemForm(): FormGroup {
    return this._businessCustomerFormService.createPrivateCustomer();
  }

  public createViewingPassSettingsForm(): FormGroup {
    return this._fb.group({
      canSubmitViewingPass: false,
    });
  }

  public createCustomerPortalViewingPassSettingsForm(): FormGroup {
    return this._fb.group({
      allSubmitViewingPass: false,
    });
  }

  public createRentalOfferSettingsForm(): FormGroup {
    return this._fb.group({
      canSubmitRentalOffer: false,
      mustHaveUploadsIndividual: this._fb.array([]),
      mustFillCustomerQuestionnaireIndividual: false,
    })
  }

  public createMustHaveUploadsArrayItemForm(): FormGroup {
    return this._fb.group({
      title: '',
      description: '',
      mandatory: true,
    });
  }

  public createBuyingOfferSettingsForm(): FormGroup {
    return this._fb.group({
      canSubmitBuyingOffer: false,
      mustHaveUploadsIndividual: this._fb.array([]),
    })
  }

  public createCustomerPortalRentalOfferSettingsForm(): FormGroup {
    return this._fb.group({
      allowPublicAccess: false,
      allSubmitRentalOffer: true,
      mustHaveUploads: this._fb.array([]),
      mustCompleteCreditCheck: false,
      mustFillCustomerQuestionnaire: false,
    });
  }

  public createCustomerPortalBuyingOfferSettingsForm(): FormGroup {
    return this._fb.group({
      allSubmitBuyingOffer: true,
      mustHaveUploads: this._fb.array([]),
    });
  }

  public createGeneralSettingsForm(): FormGroup {
    return this._fb.group({
      accessToAllDocuments: true,
      documents: this._fb.array([]),
    });
  }

  public createIndividualSettingsForm(): FormGroup {
    return this._fb.group({
      hasDocumentAccess: false,
      documents: this._fb.array([]),
    });
  }

  public createDocumentsArrayItemForm(): FormGroup {
    return this._fb.group({
      id: '',
      description: '',
      type: '',
      storageUrl: '',
      title: '',
      documentFrom: new Date().toISOString().substring(0, 10),
      state: ''
    });
  }

  public createObjectSettingsForm(): FormGroup {
    const form = this._fb.group({
      objectId: [''],
      agent: [{}],
      buyingOfferSettings: this.createCustomerPortalBuyingOfferSettingsForm(),
      rentalOfferSettings: this.createCustomerPortalRentalOfferSettingsForm(),
      viewingPassSettings: this.createCustomerPortalViewingPassSettingsForm(),
      generalSettings: this.createGeneralSettingsForm(),
    });

    return form;
  }

  public createCustomerPortalForm(): FormGroup {
    const form = this._fb.group({
      objectId: [''],
      customerType: [CustomerType.Private],
      businessCustomer: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
      }),
      customers: this._fb.array([]),
      rentalOfferSettings: this.createRentalOfferSettingsForm(),
      buyingOfferSettings: this.createBuyingOfferSettingsForm(),
      viewingPassSettings: this.createViewingPassSettingsForm(),
      individualSettings: this.createIndividualSettingsForm(),
    });

    form.get('customerType')?.valueChanges.subscribe((value) => {
      if (value === CustomerType.Private) {
        form.get('businessCustomer')?.disable();
        form.get('customers')?.enable();
      } else if (value === CustomerType.Business) {
        form.get('businessCustomer')?.enable();
        form.get('customers')?.disable();
      }
    });

    return form;
  }

  public createCustomerPortalInitialValue(data: {
    objectId: string,
    canSubmitRentalOffer: boolean,
    canSubmitBuyingOffer: boolean,
    canSubmitViewingPass: boolean,
    mustFillCustomerQuestionnaireIndividual: boolean,
    hasDocumentAccess: boolean,
  }): CreateCustomerPortal {
    const {
      objectId,
      canSubmitRentalOffer,
      canSubmitBuyingOffer,
      canSubmitViewingPass,
      mustFillCustomerQuestionnaireIndividual,
      hasDocumentAccess,
    } = data;
    const customerPortalForm = this.createCustomerPortalForm();

    const result = {
      ...customerPortalForm.value,
      objectId,
      id: '',
      customerType: CustomerType.Private,
      businessCustomer: '',
      rentalOfferSettings: {
        canSubmitRentalOffer: canSubmitRentalOffer ?? false,
        mustFillCustomerQuestionnaireIndividual: mustFillCustomerQuestionnaireIndividual ?? false,
        mustHaveUploadsIndividual: [
          {
            title: 'Lichtbildausweis',
            description: '',
            mandatory: true,
          },
          {
            title: 'Meldebestätigung (Auskunft über Wohnsitz)',
            description: '',
            mandatory: true,
          },
          {
            title: 'Einkommensbestätigung 1',
            description: 'Bei Angestellten: die letzten 3 Gehaltszettel oder Arbeitsvertrag; bei Selbständigen z.B. letztgültiger Einkommenssteuerbescheid; bei Studenten: z.B. Inskriptionsbestätigung',
            mandatory: true,
          },
          {
            title: 'Einkommensbestätigung 2',
            description: '',
            mandatory: false,
          },
          {
            title: 'Einkommensbestätigung 3',
            description: '',
            mandatory: false,
          },
        ]
      },
      buyingOfferSettings: {
        canSubmitBuyingOffer: canSubmitBuyingOffer ?? false,
      },
      viewingPassSettings: {
        canSubmitViewingPass: canSubmitViewingPass ?? false,
      },
      individualSettings: {
        hasDocumentAccess: hasDocumentAccess ?? false,
        documents: []
      },
    };

    return result;
  }
}
