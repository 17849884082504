import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { SigneeSignatureResult } from '@domains/handover-protocol';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AgentCompany } from 'src/app/common-models/agent-company';
import { RentalOffer, SignedAndCompletedRentalOffer } from './rental-offer.model';
import { realtyPriceFragment } from "../buying-offer/buying-offer-api.facade";
import { mapSignatureDataForGraphQL } from 'src/app/helper/mapSignatureDataForGraphQL';
import { EmailPreviewBodyDto } from '@types';

@Injectable({ providedIn: 'root' })
export class RentalOfferApiFacade {
  constructor(private readonly apollo: Apollo) { }

  saveRentalOffer$(data: any): Observable<RentalOffer> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation SaveRentalOffer($rentalOffer: RentalOfferInput) {
            rentalOffer(rentalOffer: $rentalOffer) {
              id
              objectId
              state
              lastVisited
              propertyDescription {
                rooms
                hasKitchen
                hasBathroom
                hasBalcony
                hasLobby
                hasCentralHeating
                hasCellar
                hasParkingSlot
                miscDetails
              }
              generalInformation {
                propertyType
                propertyTypeNotes
                shareInOwnership
                tenancyLaw
                tenancyLawApplication
                rentalStart
                rentalDuration
                rentDurationDetails
                maximumRentalPeriod
                unlimitedRentalPeriod
                waiverOfCancellationPeriod
                rentalEnd
              }
              realtyPrice {
                ...realtyPriceFragment
              }
              monthlyBilling {
                houseRent
                tax
                serviceCharges
                otherCosts
                houseRentTotal
                billingNotes
                usageBoundCosts {
                  electricity
                  gas
                  heating
                  telecommunication
                  water
                  other
                  otherDetails
                }
                vpi
                valorization
                vpiValueAdjustmentMonth
                vpiValueAdjustmentChange
              }
              handoverAndCondition {
                handoverDate
                handoverDateNotes
                handoverConditionDate
                handoverConditionNotes
                secondViewingDate
                removeCondition
                addingCondition
              }
              depositDrafterFee {
                singlePayment {
                  singlePayment
                  singlePaymentInfo
                  singlePaymentAmount
                }
                rentalDeposit {
                  depositAmount
                  depositMethod
                  depositDescription
                }
                drafterOfDeeds {
                  contractPartnerId
                  autoSendContractPartnerEmail
                  hasDrafterOfDeeds
                  name
                  address
                  phone
                  email
                  costCoverage
                  assignmentCoverage
                  drafterOfDeedsCosts
                }
                legalTransactionFee {
                  hasTransactionFee
                  tax
                  feeBase
                  amount
                }
              }
              additionalDetails {
                subleaseForbidden
                usageRules
                gotUsageRules
                houseRules
                gotHouseRules
                additionalDetails
                additionalDetailsInfo
              }
              printSettings {
                hideLandlordOnPrint
              }
              tenantType
              tenants {
                customerId
                isSponsor
                creditworthinessCertificateIsConsideredForRent
                isAwareOfRentCertificates
                selfDisclosureIsAttached
                selfDisclosureDate
                selfDisclosureType
                selfDisclosureTypeDescription
                sharesCreditworthinessInformation
                sharesCreditworthinessInformationOfPreviousTenant
                phoneNumberOfPreviousTenant
                previousTenantNotes
                sharesCreditworthinessInformationOfEmployer
                phoneNumberOfEmployer
                employerNotes
                sharesCreditworthinessOther
                sharesCreditworthinessOtherInfo
              }
              businessTenant {
                customerId
                isSponsor
                creditworthinessCertificateIsConsideredForRent
                isAwareOfRentCertificates
                selfDisclosureIsAttached
                selfDisclosureDate
                selfDisclosureType
                selfDisclosureTypeDescription
                sharesCreditworthinessInformation
                sharesCreditworthinessInformationOfPreviousTenant
                phoneNumberOfPreviousTenant
                previousTenantNotes
                sharesCreditworthinessInformationOfEmployer
                phoneNumberOfEmployer
                employerNotes
                sharesCreditworthinessOther
                sharesCreditworthinessOtherInfo
              }
              tenantRestriction {
                noLimitation
                limitationNotes
              }
              timeLimitation {
                date
              }
              landlordType
              landlords {
                customerId
              }
              landlordAcceptance
              businessLandlord {
                customerId
              }
              documents {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
              customerDocuments {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
            }
          }
          ${realtyPriceFragment}
        `,
        variables: {
          rentalOffer: data,
        },
      })
      .pipe(
        first(),
        map((result: any) => ({
          ...result.data.rentalOffer,
          lastVisited: new Date(result.data.rentalOffer?.lastVisited),
        }))
      );
  }

  sendTenantCustomerPortalNotification$(rentalOfferId: string): Observable<boolean> {
    return this.apollo.query({
      query: gql`
        query SendTenantCustomerPortalNotification($rentalOfferId: String!) {
          sendRentalOfferTenantCustomerPortalNotification(rentalOfferId: $rentalOfferId)
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        rentalOfferId,
      },
    }).pipe(first(), map((result: any) => result.data.rentalOffers));
  }

  loadRentalOffers$(objectId: string): Observable<RentalOffer[]> {
    return this.apollo.query({
      query: gql`
        query GetRentalOffers($objectId: String!) {
          rentalOffers(objectId: $objectId) {
            id
            objectId
            state
            lastVisited
            tenantType
            tenants {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
            }
            businessTenant {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                  pep {
                    isPep
                    wasPepLastYear
                    isFamilyMemberPep
                    isKnownToBeCloseToPep
                    firstName
                    lastName
                  }
                }
              }
            }
            landlordType
            landlords {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessLandlord {
              customerData {
                id
                companyNumber
                companyRegister
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                  pep {
                    isPep
                    wasPepLastYear
                    isFamilyMemberPep
                    isKnownToBeCloseToPep
                    firstName
                    lastName
                  }
                }
              }
            }
            signatureData {
              signedDocumentUrl
            }
            isMasterTemplate
            isFromCustomerPortal
            creator {
              isCreatedByMe
              name
              email
            }
            isAvailableForOwner
            customerPortal {
              showLandlordDecision
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
      },
    }).pipe(first(), map((result: any) => result.data.rentalOffers));
  }

  loadRentalOffersByCustomer$(customerId: string): Observable<RentalOffer[]> {
    return this.apollo.query({
      query: gql`
        query GetRentalOffersByCustomer($customerId: String!) {
          rentalOffersByCustomer(customerId: $customerId) {
            id
            objectId
            state
            lastVisited
            tenantType
            tenants {
              customerId
              title
              firstname
              lastname
            }
            businessTenant {
              customerData {
                id
                companyNumber
                companyRegister
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
            }
            landlordType
            landlords {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessLandlord {
              customerData {
                id
                companyNumber
                companyRegister
                customers {
                  id
                  title
                  firstname
                  lastname
                  contact {
                    email
                  }
                }
              }
            }
            signatureData {
              signedDocumentUrl
            }
            isMasterTemplate
            isFromCustomerPortal
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            isAvailableForOwner
            customerPortal {
              showLandlordDecision
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId: customerId,
      },
    }).pipe(first(), map((result: any) => result.data.rentalOffersByCustomer));
  }

  updateRentalOffersAvailability$(objectId: string, offers: any): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
          mutation UpdateRentalOffersOwnerAvailability(
            $objectId: String!
            $offers: [OfferAvailabilityInput!]
          ) {
            updateRentalOffersOwnerAvailability(
              objectId: $objectId
              offers: $offers
            )
          }
        `,
      variables: {
        offers,
        objectId,
      },
    }).pipe(
      map((result: any) => result.data.updateRentalOffersOwnerAvailability)
    );
  }

  getRentalOfferMasterTemplate$(objectId: string): Observable<RentalOffer> {
    return this.apollo.query({
      query: gql`
        query GetRentalOfferMasterTemplate($objectId: String!) {
          rentalOfferMasterTemplate(objectId: $objectId) {
            id
            objectId
            lastVisited
            propertyDescription {
              rooms
              hasKitchen
              hasBathroom
              hasBalcony
              hasLobby
              hasCentralHeating
              hasCellar
              hasParkingSlot
              miscDetails
            }
            generalInformation {
              propertyType
              propertyTypeNotes
              shareInOwnership
              tenancyLaw
              tenancyLawApplication
              rentalStart
              rentalDuration
              rentDurationDetails
              maximumRentalPeriod
              unlimitedRentalPeriod
              waiverOfCancellationPeriod
              rentalEnd
            }
            realtyPrice {
              ...realtyPriceFragment
            }
            monthlyBilling {
              houseRent
              tax
              serviceCharges
              otherCosts
              houseRentTotal
              billingNotes
              usageBoundCosts {
                electricity
                gas
                heating
                telecommunication
                water
                other
                otherDetails
              }
              vpi
              valorization
              vpiValueAdjustmentMonth
              vpiValueAdjustmentChange
            }
            handoverAndCondition {
              handoverDate
              handoverDateNotes
              handoverConditionDate
              handoverConditionNotes
              secondViewingDate
              removeCondition
              addingCondition
            }
            depositDrafterFee {
              singlePayment {
                singlePayment
                singlePaymentInfo
                singlePaymentAmount
              }
              rentalDeposit {
                depositAmount
                depositMethod
                depositDescription
              }
              drafterOfDeeds {
                contractPartnerId
                autoSendContractPartnerEmail
                hasDrafterOfDeeds
                name
                address
                phone
                email
                costCoverage
                assignmentCoverage
                drafterOfDeedsCosts
              }
              legalTransactionFee {
                hasTransactionFee
                tax
                feeBase
                amount
              }
            }
            additionalDetails {
              subleaseForbidden
              usageRules
              gotUsageRules
              houseRules
              gotHouseRules
              additionalDetails
              additionalDetailsInfo
            }
            printSettings {
              hideLandlordOnPrint
            }
            timeLimitation {
              date
            }
            landlordType
            landlords {
              customerId
            }
            businessLandlord {
              customerId
            }
            documents {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
          }
        }
        ${realtyPriceFragment}
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId },
    }).pipe(first(), map((result: any) => result.data.rentalOfferMasterTemplate));
  }

  loadRentalOfferDetails$(objectId: string, rentalOfferId: string): Observable<RentalOffer> {
    return this.apollo.query({
      query: gql`
        query GetRentalOfferById($objectId: String!, $rentalOfferId: String!) {
          rentalOffer(objectId: $objectId, rentalOfferId: $rentalOfferId) {
            id
            objectId
            state
            lastVisited
            propertyDescription {
              rooms
              hasKitchen
              hasBathroom
              hasBalcony
              hasLobby
              hasCentralHeating
              hasCellar
              hasParkingSlot
              miscDetails
            }
            generalInformation {
              propertyType
              propertyTypeNotes
              shareInOwnership
              tenancyLaw
              tenancyLawApplication
              rentalStart
              rentalDuration
              rentDurationDetails
              maximumRentalPeriod
              unlimitedRentalPeriod
              waiverOfCancellationPeriod
              rentalEnd
            }
            realtyPrice {
              ...realtyPriceFragment
            }
            monthlyBilling {
              houseRent
              tax
              serviceCharges
              otherCosts
              houseRentTotal
              billingNotes
              usageBoundCosts {
                electricity
                gas
                heating
                telecommunication
                water
                other
                otherDetails
              }
              vpi
              valorization
              vpiValueAdjustmentMonth
              vpiValueAdjustmentChange
            }
            handoverAndCondition {
              handoverDate
              handoverDateNotes
              handoverConditionDate
              handoverConditionNotes
              secondViewingDate
              removeCondition
              addingCondition
            }
            depositDrafterFee {
              singlePayment {
                singlePayment
                singlePaymentInfo
                singlePaymentAmount
              }
              rentalDeposit {
                depositAmount
                depositMethod
                depositDescription
              }
              drafterOfDeeds {
                contractPartnerId
                autoSendContractPartnerEmail
                hasDrafterOfDeeds
                name
                address
                phone
                email
                costCoverage
                assignmentCoverage
                drafterOfDeedsCosts
              }
              legalTransactionFee {
                hasTransactionFee
                tax
                feeBase
                amount
              }
            }
            additionalDetails {
              subleaseForbidden
              usageRules
              gotUsageRules
              houseRules
              gotHouseRules
              additionalDetails
              additionalDetailsInfo
            }
            printSettings {
              hideLandlordOnPrint
            }
            tenantType
            tenants {
              customerId
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
              isSponsor
              creditworthinessCertificateIsConsideredForRent
              isAwareOfRentCertificates
              selfDisclosureIsAttached
              selfDisclosureDate
              selfDisclosureType
              selfDisclosureTypeDescription
              sharesCreditworthinessInformation
              sharesCreditworthinessInformationOfPreviousTenant
              phoneNumberOfPreviousTenant
              previousTenantNotes
              sharesCreditworthinessInformationOfEmployer
              phoneNumberOfEmployer
              employerNotes
              sharesCreditworthinessOther
              sharesCreditworthinessOtherInfo
            }
            businessTenant {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                  }
                  phone
                  email
                }
                customers {
                  id
                  birthdate
                  title
                  firstname
                  lastname
                  nationality
                  contact {
                    address {
                      country
                      city
                      zipCode
                      streetName
                      buildingNumber
                      doorNumber
                      stairway
                      longitude
                      latitude
                    }
                    phone
                    email
                  }
                  pep {
                    isPep
                    wasPepLastYear
                    isFamilyMemberPep
                    isKnownToBeCloseToPep
                    firstName
                    lastName
                  }
                  IDImages {
                    id
                    storageUrl
                    type
                    description
                  }
                }
              }
              isSponsor
              creditworthinessCertificateIsConsideredForRent
              isAwareOfRentCertificates
              selfDisclosureIsAttached
              selfDisclosureDate
              selfDisclosureType
              selfDisclosureTypeDescription
              sharesCreditworthinessInformation
              sharesCreditworthinessInformationOfPreviousTenant
              phoneNumberOfPreviousTenant
              previousTenantNotes
              sharesCreditworthinessInformationOfEmployer
              phoneNumberOfEmployer
              employerNotes
              sharesCreditworthinessOther
              sharesCreditworthinessOtherInfo
            }
            tenantRestriction {
              noLimitation
              limitationNotes
            }
            timeLimitation {
              date
            }
            landlordType
            landlords {
              customerId
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
            }
            landlordAcceptance
            businessLandlord {
              customerId
              customerData {
                id
                companyNumber
                companyRegister
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                  }
                  phone
                  email
                }
                customers {
                  id
                  birthdate
                  title
                  firstname
                  lastname
                  nationality
                  contact {
                    address {
                      country
                      city
                      zipCode
                      streetName
                      buildingNumber
                      doorNumber
                      stairway
                      longitude
                      latitude
                    }
                    phone
                    email
                  }
                  pep {
                    isPep
                    wasPepLastYear
                    isFamilyMemberPep
                    isKnownToBeCloseToPep
                    firstName
                    lastName
                  }
                  IDImages {
                    id
                    storageUrl
                    type
                    description
                  }
                }
              }
            }
            documents {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
            customerDocuments {
              id
              storageUrl
              type
              description
              title
              documentFrom
              state
            }
            signatureData {
              tenants {
                type
                signatureAsSvg
                signedViaTAN
                signatureText
                present
                date
              }
              signedDocumentUrl
            }
          }
        }
        ${realtyPriceFragment}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        rentalOfferId: rentalOfferId,
      },
    }).pipe(first(), map((result: any) => result.data.rentalOffer));
  }

  createRentalOfferDocument$(
    objectId: string,
    rentalOfferId: string,
    agentCompany: AgentCompany,
  ): Observable<string> {

    return this.apollo.query({
      query: gql`
        query CreateRentalOfferDocument(
          $objectId: String!
          $rentalOfferId: String!
          $agentCompany: AgentCompany!
        ) {
          rentalOfferDocument(
            objectId: $objectId
            rentalOfferId: $rentalOfferId
            agentCompany: $agentCompany
          ) {
            url
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        rentalOfferId: rentalOfferId,
        agentCompany: agentCompany,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.rentalOfferDocument.url),
    );
  }

  deleteRentalOffer$(rentalOfferId: string): Observable<Boolean> {
    return this.apollo.mutate({
      mutation: gql`
          mutation DeleteRentalOffer($rentalOfferId: String!) {
            deleteRentalOffer(rentalOfferId: $rentalOfferId)
          }
        `,
      variables: {
        rentalOfferId,
      },
    }).pipe(
      map((result: any) => result.data.deleteRentalOffer)
    );
  }

  updateRentalOfferMasterTemplate$(rentalOfferId: string, objectId: string): Observable<Boolean> {
    return this.apollo.mutate({
      mutation: gql`
          mutation UpdateRentalOfferMasterTemplate(
            $objectId: String!
            $rentalOfferId: String!
          ) {
            updateRentalOfferMasterTemplate(
              objectId: $objectId
              rentalOfferId: $rentalOfferId
            )
          }
        `,
      variables: {
        rentalOfferId,
        objectId,
      },
    }).pipe(
      map((result: any) => result.data.updateRentalOfferMasterTemplate)
    );
  }

  resetRentalOffer$(objectId: string, rentalOfferId: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
          mutation ResetRentalOffer(
            $objectId: String!
            $rentalOfferId: String!
          ) {
            resetRentalOffer(
              objectId: $objectId
              rentalOfferId: $rentalOfferId
            )
          }
        `,
      variables: {
        rentalOfferId,
        objectId,
      },
    }).pipe(
      map((result: any) => result.data.resetRentalOffer)
    );
  }

  signAndCompleteRentalOffer$(
    objectId: string,
    rentalOfferId: string,
    tenantsSignatureData: SigneeSignatureResult[],
    landlordSignatureData: SigneeSignatureResult[] | null,
    landlordAcceptance: string | null,
    agentCompany: AgentCompany
  ): Observable<SignedAndCompletedRentalOffer> {
    return this.apollo.mutate({
      mutation: gql`
        mutation SignAndCompleteRentalOffer(
          $signatureInput: RentalOfferSignatureInput!
          $agentCompany: AgentCompany!
        ) {
          signAndCompleteRentalOffer(
            signatureInput: $signatureInput
            agentCompany: $agentCompany
          ) {
            id
            state
            lastVisited
            objectId
            signatureData {
              signedDocumentUrl
            }
          }
        }
      `,
      variables: {
        signatureInput: {
          objectId,
          rentalOfferId,
          landlordAcceptance,
          tenantsSignatureData: tenantsSignatureData.map((s) => mapSignatureDataForGraphQL(s)),
          landlordSignatureData: landlordSignatureData ?
            landlordSignatureData.map((s) => mapSignatureDataForGraphQL(s)) : null,
        },
        agentCompany,
      },
    })
      .pipe(
        map((result: any) => {
          const untypedRentalOffer = result.data.signAndCompleteRentalOffer;

          const rentalOffer = untypedRentalOffer as SignedAndCompletedRentalOffer;
          rentalOffer.lastVisited = new Date(rentalOffer.lastVisited);

          return rentalOffer;
        })
      );
  }

  sendRentalOfferEmail$(
    rentalOfferId: string,
    emailData: any,
    agentCompany: AgentCompany,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query TriggerSendRentalOfferEmailQuery(
          $rentalOfferId: String!
          $emailData: EmailData!
          $agentCompany: AgentCompany!
        ) {
          rentalOfferSendEmail(
            rentalOfferId: $rentalOfferId
            emailData: $emailData
            agentCompany: $agentCompany
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        rentalOfferId,
        emailData,
        agentCompany
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.rentalOfferSendEmail;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  getRentalOfferEmailVariables$(
    rentalOfferId: string,
    documentUrl: string,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetRentalOfferEmailVariables(
          $rentalOfferId: String!
          $documentUrl: String!
        ) {
          rentalOfferEmailVariables(
            rentalOfferId: $rentalOfferId
            documentUrl: $documentUrl
          ) {
            propertyAddress
            documentUrl
            signature
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        rentalOfferId,
        documentUrl,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data?.rentalOfferEmailVariables),
    );
  }

  sendCustomerPortalLink$(
    objectId: string,
    rentalOfferId: string,
    handInOffer: boolean,
    emailData: any,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query SendCustomerPortalLink(
          $objectId: String!
          $rentalOfferId: String!
          $handInOffer: Boolean!
          $emailData: EmailData!
        ) {
          rentalOfferCustomerPortalLink(
            objectId: $objectId
            rentalOfferId: $rentalOfferId
            handInOffer: $handInOffer
            emailData: $emailData
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        objectId,
        rentalOfferId,
        handInOffer,
        emailData,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.rentalOfferCustomerPortalLink;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  sendLandlordCustomerPortalLink$(
    objectId: string,
    rentalOfferId: string,
    emailData: any,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query SendLandlordCustomerPortalLink(
          $objectId: String!
          $rentalOfferId: String!
          $emailData: EmailData!
        ) {
          rentalOfferLandlordCustomerPortalLink(
            objectId: $objectId
            rentalOfferId: $rentalOfferId
            emailData: $emailData
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        objectId,
        rentalOfferId,
        emailData,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.rentalOfferLandlordCustomerPortalLink;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  compressFiles$(objectId: string, rentalOfferId: string, filePaths: string[], filenames: string[]): Observable<string> {
    return this.apollo.query({
      query: gql`
        query CompressRentalOfferFiles(
          $objectId: String!
          $rentalOfferId: String!
          $filePaths: [String!]!
          $filenames: [String!]!
        ) {
          compressRentalOfferFiles(
            objectId: $objectId
            rentalOfferId: $rentalOfferId
            filePaths: $filePaths
            filenames: $filenames
          )
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        rentalOfferId: rentalOfferId,
        filePaths: filePaths,
        filenames: filenames,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.compressRentalOfferFiles),
    );
  }
}


