import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { customerPortalSelectors, CustomerPortalFeatureState } from './store';
import { CustomerPortalActions } from './store/action-types';
import { CustomerPortalApiFacade } from './customer-portal-api.facade';
import { Observable } from 'rxjs';
import { CreateCustomerPortal, CustomerPortal, CustomerPortalAgent } from './customer-portal.model';
import { EmailPreviewBodyDto } from '@types';

@Injectable({ providedIn: 'root' })
export class CustomerPortalDataFacade {
  customerPortals$ = this._store$.select(customerPortalSelectors.customerPortals);
  customerPortalsLoading$ = this._store$.select(customerPortalSelectors.customerPortalsLoading);
  customerPortalFeedback$ = this._store$.select(customerPortalSelectors.customerPortalFeedback);
  customerPortalFeedbackLoading$ = this._store$.select(customerPortalSelectors.customerPortalFeedbackLoading);
  customerPortalDetails$ = this._store$.select(customerPortalSelectors.customerPortalDetails);
  customerPortalDetailsLoading$ = this._store$.select(customerPortalSelectors.customerPortalDetailsLoading);
  savingProcessLoading$ = this._store$.select(customerPortalSelectors.savingProcessLoading);
  savingProcessHasError$ = this._store$.select(customerPortalSelectors.savingProcessHasError);

  constructor(
    private readonly _store$: Store<CustomerPortalFeatureState>,
    private readonly _customerPortalApiFacade: CustomerPortalApiFacade,
  ) { }

  public loadCustomerPortals(objectId: string): void {
    this._store$.dispatch(CustomerPortalActions.LoadCustomerPortals({ objectId }));
  }

  public loadCustomerPortalFeedback(objectId: string): void {
    this._store$.dispatch(CustomerPortalActions.LoadCustomerPortalFeedback({ objectId }));
  }

  public loadCustomerPortalsByCustomer(customerId: string): void {
    this._store$.dispatch(CustomerPortalActions.LoadCustomerPortalsByCustomer({ customerId }));
  }

  public loadCustomerPortalDetails(objectId: string, id: string): void {
    this._store$.dispatch(CustomerPortalActions.LoadCustomerPortalDetails({ objectId, id }));
  }

  public deleteCustomerPortal(id: string): void {
    this._store$.dispatch(CustomerPortalActions.DeleteCustomerPortal({ id }));
  }

  public saveCustomerPortal(data: CreateCustomerPortal, isFinal: boolean = false): void {
    this._store$.dispatch(CustomerPortalActions.SaveCustomerPortal({ data, isFinal }));
  }

  public sendCustomerPortalEmail(objectId: string, id: string, emailData: any): void {
    const emailPayload = { objectId, id, emailData };
    this._store$.dispatch(CustomerPortalActions.SendCustomerPortalEmail(emailPayload));
  }

  public getTeamCustomerPortalAgents$(): Observable<CustomerPortalAgent[]> {
    return this._customerPortalApiFacade.getTeamCustomerPortalAgents$();
  }

  public createCustomerPortal$(data: CreateCustomerPortal): Observable<CustomerPortal> {
    return this._customerPortalApiFacade.saveCustomerPortal$(data);
  }

  public getCustomerPortalEmailPreview$(data: EmailPreviewBodyDto): Observable<any> {
    return this._customerPortalApiFacade.getCustomerPortalEmailPreview$(data);
  }

  public getCustomerPortalOwnerEmailPreview$(data: EmailPreviewBodyDto): Observable<any> {
    return this._customerPortalApiFacade.getCustomerPortalOwnerEmailPreview$(data);
  }

  public getCustomerPortalDetails$(objectId: string, id: string): Observable<CustomerPortal> {
    return this._customerPortalApiFacade.loadCustomerPortalDetails$(objectId, id);
  }
}
