import { Action, createReducer, on } from '@ngrx/store';
import { setProfile } from './profile.actions';

export interface Profile {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  isRemax: boolean;
  themeConfig?: string;
  legalEntity: string;
  officeName: string;
  phone: string;
  officeAddress: string;
  companyLogo: string | undefined;
  googleReviewLink: string;
  createdTimeStamp: number;
}

const initialState: Profile = {
  id: '',
  givenName: '',
  familyName: '',
  email: '',
  isRemax: false,
  themeConfig: '',
  legalEntity: '',
  officeAddress: '',
  officeName: '',
  phone: '',
  companyLogo: '',
  googleReviewLink: '',
  createdTimeStamp: 0
};

const createReducerInternal = createReducer(
  initialState,
  on(setProfile, (state, payload) => ({
    ...state,
    ...payload.profile,
  }))
);

export function profileReducer(
  state: Profile | undefined,
  action: Action
): Profile {
  return createReducerInternal(state, action);
}
