import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import {
  DocumentsSettings,
  EmailSettings, ExclusiveAgreementSettings,
  ObjectsListSettings,
  PreListingSettings,
  Settings,
  UpdateProfileSettingsDTO,
  UserProfileSettings,
  ViewingPassSettings,
} from '../store/settings/settings.reducers';
import { catchError, first, map, tap } from 'rxjs/operators';
import { cleanTypenames } from './cleanup';
import { Store } from '@ngrx/store';
import {
  failedToLoadSettings,
  setSettings,
} from '../store/settings/settings.actions';
import { TranslationService } from 'src/app/i18n/TranslationService';
import { CountrySettings } from '@types';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private apollo: Apollo,
    private store: Store,
    private _translationService: TranslationService,
  ) { }

  getDocumentsSettings(): Observable<DocumentsSettings> {
    return this.apollo.query({
      query: gql`
        query GetDocumentsSettings {
          documentsSettings {
            overallFontSize
            taxBase
            countrySettings
          }
        }
      `,
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: any) => result.data.documentsSettings)
    );
  }

  getSettings(): Observable<Settings> {
    return this.apollo.query({
      query: gql`
        ${emailTemplateFragment}
        ${emailTemplatesFragment}
        query GetSettings {
          settings {
            viewingPass {
              absoluteFee
              simplifiedFAGG
            }
            documentsSettings {
              overallFontSize
              taxBase
              countrySettings
            }
            exclusiveAgreement {
              activateSigning
            }
            preListing {
              title
              description
            }
            objectsList {
              myNames
            }
            email {
              signature
              emailRecipients
              emailClient {
                host
                port
                secure
                auth {
                  user
                  pass
                }
                imapHost
                imapPort
              }
              templates {
                ...emailTemplatesFragment
              }
              i18n {
                de {
                  templates {
                    ...emailTemplatesFragment
                  }
                }
              }
            }
            accountLinks {
              identityProvider
              userId
              userName
            }
            profileSettings {
              platformExportSettings {
                exportToMaklerSalzburg
              }
              integrations {
                onOffice {
                  userId
                  apiToken
                }
                edireal {
                  userId
                }
                justimmo {
                  userId
                }
                remax {
                  userId
                }
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    }).pipe(
      catchError((error) => {
        this.store.dispatch(failedToLoadSettings());
        return throwError(error);
      }),
      map((result: any) => cleanTypenames(result.data.settings))
    );
  }

  getPlatformExportSettings(): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetPlatformExportSettings {
          platformExportSettings {
            exportToMaklerSalzburg
          }
        }
      `,
      fetchPolicy: 'no-cache',
    }).pipe(
      map((result: any) => result.data.platformExportSettings)
    );
  }

  updateEmailSettings(data: EmailSettings): Observable<unknown> {
    return this.apollo.mutate({
      mutation: gql`
        ${emailTemplateFragment}
        ${emailTemplatesFragment}

        mutation UpdateEmailSettings($settings: UpdateEmailSettings!) {
          emailSettings(settings: $settings) {
            signature
            emailRecipients
            emailClient {
              host
              port
              secure
              auth {
                user
                pass
              }
              imapPort
              imapHost
            }
            templates {
              ...emailTemplatesFragment
            }
            i18n {
              de {
                templates {
                  ...emailTemplatesFragment
                }
              }
            }
          }
        }
      `,
      variables: {
        settings: data,
      },
      fetchPolicy: 'no-cache',
    });
  }

  updateDocumentsSettings(data: any): Observable<unknown> {
    return this.apollo.mutate({
      mutation: gql`
        mutation UpdateDocumentsSettings($settings: UpdateDocumentsSettings!) {
          documentsSettings(settings: $settings) {
            overallFontSize
            taxBase
            countrySettings
          }
        }
      `,
      variables: {
        settings: data,
      },
      fetchPolicy: 'no-cache',
    });
  }

  updateViewingPassSettings(data: ViewingPassSettings): Observable<unknown> {
    return this.apollo.mutate({
      mutation: gql`
        mutation UpdateViewingPassSettings($settings: UpdateViewingPassSettings!) {
          viewingPassSettings(settings: $settings) {
            absoluteFee
            simplifiedFAGG
          }
        }
      `,
      variables: {
        settings: data,
      },
      fetchPolicy: 'no-cache',
    });
  }

  updateExclusiveAgreementSettings(data: ExclusiveAgreementSettings): Observable<unknown> {
    return this.apollo.mutate({
      mutation: gql`
        mutation UpdateExclusiveAgreementSettings($settings: UpdateExclusiveAgreementSettings!) {
          exclusiveAgreementSettings(settings: $settings) {
            activateSigning
          }
        }
      `,
      variables: {
        settings: data,
      },
      fetchPolicy: 'no-cache',
    });
  }

  updatePreListingSettings(data: PreListingSettings): Observable<unknown> {
    return this.apollo.mutate({
      mutation: gql`
        mutation UpdatePreListingSettings($settings: UpdatePreListingSettings!) {
          preListingSettings(settings: $settings) {
            title
            description
          }
        }
      `,
      variables: {
        settings: data,
      },
      fetchPolicy: 'no-cache',
    });
  }

  updateObjectsListSettings$(data: ObjectsListSettings): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation UpdateObjectListSettings($settings: UpdateObjectsListSettings!) {
          objectsListSettings(settings: $settings) {
            myNames
          }
        }
      `,
      variables: {
        settings: data,
      },
      fetchPolicy: 'no-cache',
    });
  }

  updateUserProfileSettings$(data: UpdateProfileSettingsDTO): Observable<UserProfileSettings> {
    return this.apollo.mutate({
      mutation: gql`
        mutation UpdateUserProfileSettings($settings: UpdateProfileSettings!) {
          profileSettings(settings: $settings) {
            platformExportSettings {
              exportToMaklerSalzburg
            }
            integrations {
              onOffice {
                userId
                apiToken
              }
              edireal {
                userId
              }
              justimmo {
                userId
              }
              remax {
                userId
              }
            }
          }
        }
      `,
      variables: {
        settings: data,
      },
      fetchPolicy: 'no-cache',
    }).pipe(map((result: any) => result.profileSettings));
  }

  ensureSettingsInitialisation(): Observable<Boolean> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation EnsureSettingsInitialisation {
            ensureSettingsInitialisation
          }
        `,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      })
      .pipe(
        map((result: any) => {
          const hasErrors = result.errors?.length || 0 > 0;
          if (hasErrors) {
            return false;
          } else {
            return true;
          }
        })
      );
  }

  loadSettings(): void {
    this.getSettings().pipe(
      first(),
      tap((settings) => {
        this.store.dispatch(setSettings({ settings: settings }));
        this._translationService.applyCountrySettingsLanguage(settings?.documentsSettings?.countrySettings);
      }),
    ).subscribe();
  }
}

export const emailTemplateFragment = gql`
  fragment emailTemplateFragment on EmailTemplate {
    title
    body
    attachments {
      id
      storageUrl
      type
      description
    }
  }
`;

export const emailTemplatesFragment = gql`
  fragment emailTemplatesFragment on EmailTemplates {
    viewingPass {
      customers {
        ...emailTemplateFragment
      }
      others {
        ...emailTemplateFragment
      }
    }
    rentalOffer {
      tenants {
        ...emailTemplateFragment
      }
      landlord {
        ...emailTemplateFragment
      }
      others {
        ...emailTemplateFragment
      }
    }
    buyingOffer {
      buyers {
        ...emailTemplateFragment
      }
      sellers {
        ...emailTemplateFragment
      }
      others {
        ...emailTemplateFragment
      }
    }
    handoverProtocol {
      transferors {
        ...emailTemplateFragment
      }
      transferees {
        ...emailTemplateFragment
      }
      others {
        ...emailTemplateFragment
      }
    }
    exclusiveAgreement {
      contractEntities {
        ...emailTemplateFragment
      }
      others {
        ...emailTemplateFragment
      }
    }
    plainAgreement {
      contractEntities {
        ...emailTemplateFragment
      }
      others {
        ...emailTemplateFragment
      }
    }
    customerPortal {
      customers {
        ...emailTemplateFragment
      }
      owners {
        ...emailTemplateFragment
      }
    }
  }
`;
