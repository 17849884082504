import { Action, createReducer, on } from '@ngrx/store';
import { setSettings } from './settings.actions';
import { Language } from 'src/app/i18n/config';
import { CountrySettings } from '@types';

export interface Settings {
  viewingPass: ViewingPassSettings;
  exclusiveAgreement: ExclusiveAgreementSettings;
  preListing: PreListingSettings;
  objectsList: ObjectsListSettings;
  email: EmailSettings;
  accountLinks: AccountLinkingSetting[];
  profileSettings: UserProfileSettings;
  documentsSettings: DocumentsSettings;
}

export interface DocumentsSettings {
  overallFontSize: string;
  taxBase: number;
  countrySettings: CountrySettings;
}

export interface AccountLinkingSetting {
  identityProvider: string;
  userId: string;
  userName: string;
}

export interface ViewingPassSettings {
  absoluteFee: boolean;
  simplifiedFAGG: boolean;
}

export interface PreListingSettings {
  title: string;
  description?: string;
}

export interface ObjectsListSettings {
  myNames: string[];
}

export interface UserProfileSettings {
  platformExportSettings: {
    exportToMaklerSalzburg: boolean;
  },
  integrations: {
    onOffice: {
      userId: string;
      apiToken: string;
    };
    edireal: {
      userId: string;
    };
    justimmo: {
      userId: string;
    };
    remax: {
      userId: string;
    };
  };
}

export interface UpdateProfileSettingsDTO {
  onOfficeApiToken?: string;
  onOfficeUserId?: string;
  edirealUserId?: string;
  justimmoUserId?: string;
  remaxUserId?: string;
  platformExportSettings?: UpdatePlatformExports;
}

export interface UpdatePlatformExports {
  exportToMaklerSalzburg: boolean;
}

export interface ExclusiveAgreementSettings {
  activateSigning: boolean;
}

export interface EmailTemplates {
  viewingPass: {
    customers: EmailTemplate;
    others: EmailTemplate;
  };
  rentalOffer: {
    tenants: EmailTemplate;
    landlord: EmailTemplate;
    others: EmailTemplate;
  };
  buyingOffer: {
    buyers: EmailTemplate;
    sellers: EmailTemplate;
    others: EmailTemplate;
  };
  handoverProtocol: {
    transferors: EmailTemplate;
    transferees: EmailTemplate;
    others: EmailTemplate;
  };
  exclusiveAgreement: {
    contractEntities: EmailTemplate;
    others: EmailTemplate;
  };
  plainAgreement: {
    contractEntities: EmailTemplate;
    others: EmailTemplate;
  };
  customerPortal: {
    customers: EmailTemplate;
    owners: EmailTemplate;
  };
};

export interface EmailSettings {
  signature: string;
  emailRecipients: string[];
  templates: EmailTemplates;
  i18n: {
    [Language.DE]: {
      templates: EmailTemplates;
    };
  };
}

export interface EmailTemplate {
  title: string;
  body: string;
  attachments: EmailTemplateAttachment[];
}

export interface EmailTemplateAttachment {
  id: string;
  storageUrl: string;
  type: string;
  description: string;
  title: string;
  documentFrom: string;
}

const emailTemplate: EmailTemplate = {
  title: '',
  body: '',
  attachments: [],
};

const emailsTemplates: EmailTemplates = {
  viewingPass: {
    customers: emailTemplate,
    others: emailTemplate,
  },
  rentalOffer: {
    tenants: emailTemplate,
    landlord: emailTemplate,
    others: emailTemplate,
  },
  buyingOffer: {
    buyers: emailTemplate,
    sellers: emailTemplate,
    others: emailTemplate,
  },
  handoverProtocol: {
    transferors: emailTemplate,
    transferees: emailTemplate,
    others: emailTemplate,
  },
  exclusiveAgreement: {
    contractEntities: emailTemplate,
    others: emailTemplate,
  },
  plainAgreement: {
    contractEntities: emailTemplate,
    others: emailTemplate,
  },
  customerPortal: {
    customers: emailTemplate,
    owners: emailTemplate,
  },
};

const initialState: Settings = {
  viewingPass: {
    absoluteFee: true,
    simplifiedFAGG: false,
  },
  exclusiveAgreement: {
    activateSigning: false,
  },
  documentsSettings: {
    overallFontSize: '',
    taxBase: 20,
    countrySettings: CountrySettings.AT,
  },
  preListing: {
    title: '',
    description: '',
  },
  objectsList: {
    myNames: [],
  },
  email: {
    signature: '',
    emailRecipients: [],
    templates: emailsTemplates,
    i18n: {
      [Language.DE]: {
        templates: emailsTemplates
      },
    },
  },
  accountLinks: [],
  profileSettings: {
    platformExportSettings: {
      exportToMaklerSalzburg: false,
    },
    integrations: {
      onOffice: {
        userId: '',
        apiToken: '',
      },
      edireal: {
        userId: '',
      },
      justimmo: {
        userId: '',
      },
      remax: {
        userId: '',
      }
    },
  },
};

const createReducerInternal = createReducer(
  initialState,
  on(setSettings, (state, payload) => ({
    ...state,
    ...payload.settings,
  })),
);

export function settingsReducer(state: Settings | undefined, action: Action): Settings {
  return createReducerInternal(state, action);
}
