import { ActionsSubject, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { viewingPassSelectors, ViewingPassFeatureState } from './store';
import { ViewingPassActions } from './store/action-types';
import { ViewingPassApiFacade } from './viewing-pass-api.facade';
import { Observable } from 'rxjs';
import { DocumentService } from 'services/document.service';
import { getAgentCompany } from 'src/app/common-models/mapping';
import { AuthService } from 'services/auth.service';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import {
  ViewingPassCreation,
  ViewingPassSignatureCreation,
  ViewingPass,
} from './viewing-pass.model';
import { ofType } from "@ngrx/effects";
import { EmailPreviewBodyDto } from '@types';

@Injectable({ providedIn: 'root' })
export class ViewingPassDataFacade {
  viewingPasses$ = this._store$.select(viewingPassSelectors.viewingPasses);
  viewingPassesLoading$ = this._store$.select(viewingPassSelectors.viewingPassesLoading);
  viewingPassDetails$ = this._store$.select(viewingPassSelectors.viewingPassDetails);
  viewingPassDetailsLoading$ = this._store$.select(viewingPassSelectors.viewingPassDetailsLoading);
  savingProcessLoading$ = this._store$.select(viewingPassSelectors.savingProcessLoading);
  savingProcessHasError$ = this._store$.select(viewingPassSelectors.savingProcessHasError);

  constructor(
    private readonly _store$: Store<ViewingPassFeatureState>,
    private readonly _viewingPassApiFacade: ViewingPassApiFacade,
    private readonly _authService: AuthService,
    private readonly _docService: DocumentService,
    private readonly actionsListener$: ActionsSubject,
  ) { }

  public loadViewingPasses(objectId: string): void {
    this._store$.dispatch(ViewingPassActions.LoadViewingPasses({ objectId }));
  }

  public loadViewingPassesByCustomer(customerId: string): void {
    this._store$.dispatch(ViewingPassActions.LoadViewingPassesByCustomer({ customerId }));
  }

  public loadViewingPassDetails(objectId: string, viewingPassId: string): void {
    this._store$.dispatch(ViewingPassActions.LoadViewingPassDetails({ objectId, viewingPassId }));
  }

  public deleteViewingPass(viewingPassId: string): void {
    this._store$.dispatch(ViewingPassActions.DeleteViewingPass({ viewingPassId }));
  }

  public saveViewingPass(viewingPass: ViewingPassCreation, isFinal: boolean = false, shouldPrint: boolean = false): void {
    this._store$.dispatch(ViewingPassActions.SaveViewingPass({ viewingPass, isFinal, shouldPrint }));
  }

  public sendViewingPassEmail(viewingPassId: string, emailData: any): void {
    const agentCompany = getAgentCompany(this._authService);
    const emailPayload = { viewingPassId, emailData, agentCompany };
    this._store$.dispatch(ViewingPassActions.SendViewingPassEmail(emailPayload));
  }

  public sendCustomerPortalLink(objectId: string, viewingPassId: string, handInPass: boolean, emailData: any): void {
    this._store$.dispatch(ViewingPassActions.SendCustomerPortalLink({ objectId, viewingPassId, handInPass, emailData }));
  }

  public signAndCompleteViewingPass(viewingPassSignature: ViewingPassSignatureCreation): void {
    this._store$.dispatch(ViewingPassActions.SignAndCompleteViewingPass({ viewingPassSignature }));
  }

  public failedCreateAndUploadViewingPassDocument(): void {
    this._store$.dispatch(ViewingPassActions.CreateAndUploadViewingPassDocumentFailed());
  }

  public createViewingPass$(viewingPass: ViewingPassCreation): Observable<ViewingPass> {
    return this._viewingPassApiFacade.saveViewingPass$(viewingPass);
  }

  public getLastViewingPassDetails$(objectId: string): Observable<ViewingPass> {
    return this._viewingPassApiFacade.getLastViewingPassDetails$(objectId);
  }

  public getSignedViewingPassesAmount$(objectId: string): Observable<{ amount: number }> {
    return this._viewingPassApiFacade.getSignedViewingPassesAmount$(objectId);
  }

  public downloadSignedViewingPassesDocs$(objectId: string): Observable<string> {
    return this._viewingPassApiFacade.getSignedViewingPassesDocsUrl$(objectId).pipe(
      map(data => data.storageUrl),
      tap((storageUrl) => {
        if (storageUrl) {
          const lastIndex = storageUrl.lastIndexOf('/');
          const a = document.createElement('a');
          a.download = storageUrl.substring(lastIndex + 1);
          a.href = storageUrl;
          document.body.appendChild(a);
          a.click();
        }
      })
    );
  }

  public getViewingPassEmailVariables$(viewingPassId: string): Observable<any> {
    const agentCompany = getAgentCompany(this._authService);
    return this._viewingPassApiFacade.getViewingPassEmailVariables$(viewingPassId, agentCompany);
  }

  public getViewingPassDetails$(objectId: string, viewingPassId: string): Observable<ViewingPass> {
    return this._viewingPassApiFacade.loadViewingPassDetails$(objectId, viewingPassId);
  }

  public createViewingPassDocument$(objectId: string, viewingPassId: string): Observable<string> {
    const agentCompany = getAgentCompany(this._authService);
    return this._viewingPassApiFacade.createViewingPassDocument$(objectId, viewingPassId, agentCompany);
  }

  public compressFiles$(objectId: string, viewingPassId: string, filePaths: string[], fileNames: string[]): Observable<string> {
    return this._viewingPassApiFacade.compressFiles$(objectId, viewingPassId, filePaths, fileNames);
  }

  public showViewingPassDocument(objectId: string, viewingPassId: string): void {
    this.createViewingPassDocument$(objectId, viewingPassId).pipe(
      first(),
      tap((url) => {
        this._docService.openDocumentLink(url);
      }),
      catchError((err) => {
        this.failedCreateAndUploadViewingPassDocument();
        throw err;
      }),
    ).subscribe();
  }

  public saveViewingPassSucceeded$() {
    return this.actionsListener$.pipe(
      ofType(ViewingPassActions.SaveViewingPassSucceded)
    );
  }
}
