import { Injectable } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CONDITION, ENERGYPASSCLASS, ObjectCreation } from "./object.model";
import { DOCSTATUS, ENERGYPASSAVAILABLE, ENERGYPASSRESPONSIBLE } from "../exclusive-agreement";
import { CustomerType } from "../../common-components/customer-type-selector/model";
import { BusinessCustomerFormService } from "@domains/business-customer";
import { CustomerFormService } from "@domains/customer";
import { startWith } from "rxjs/operators";

export interface UsageBoundCostsForm {
  givenLater: FormControl<boolean | null>
  costs: FormControl<number | null>;
  operatingCostsTax: FormControl<boolean | null>;
  electricity: FormControl<boolean | null>;
  heating: FormControl<boolean | null>;
  telecommunication: FormControl<boolean | null>;
  water: FormControl<boolean | null>;
  other: FormControl<boolean | null>;
  otherDetails: FormControl<string | null>;
}

export interface AddressForm {
  manualCreation: FormControl<boolean | null>;
  country: FormControl<string | null>;
  city: FormControl<string | null>;
  zipCode: FormControl<string | null>;
  streetName: FormControl<string | null>;
  buildingNumber: FormControl<string | null>;
  doorNumber: FormControl<string | null>;
  stairway: FormControl<string | null>;
  longitude: FormControl<number | null>;
  latitude: FormControl<number | null>;
  state: FormControl<string | null>;
  displayAddress: FormControl<string | null>;

}

@Injectable({ providedIn: 'root' })
export class ObjectFormService {
  constructor(
    private readonly _fb: FormBuilder,
    private readonly _customerFormService: CustomerFormService,
    private readonly _businessCustomerFormService: BusinessCustomerFormService,
  ) { }

  public initObjectForm(): FormGroup {
    const formGroup = this._fb.group({
      objectType: [null, [Validators.required]],
      orderType: [null, [Validators.required]],
      objectStatus: ['active'],
      price: [0],
      priceDetails: this.createPriceDetailsFormGroup(),
      movingInDate: [null],
      movingInDateDetails: '',
      roomsAndArea: this.createRoomsAndAreaFormGroup(),
      condition: this.createConditionFormGroup(),
      inventoryList: this._fb.array([]),
      propertyNotes: this._fb.array([]),
      propertyAttributes: this.createPropertyAttributesFormGroup(),
      energyPass: this.createPropertyEnergyPassFormGroup(),
      additionalInformation: this.createAdditionalInformationFormGroup(),
      documents: this._fb.array([]),
      neededDocuments: this.createNeededDocumentsFormGroup(),
      images: this._fb.array([]),
      address: this.createAddressFormGroup(),
      ownerType: [CustomerType.Private],
      owners: this._fb.array([]),
      businessOwner: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
      })
    }) as FormGroup;

    formGroup.get('ownerType')?.valueChanges.pipe(
      startWith(CustomerType.Private)
    ).subscribe((value) => {
      if (value === CustomerType.Private) {
        formGroup.get('businessOwner')?.disable();
        formGroup.get('owners')?.enable();
      } else if (value === CustomerType.Business) {
        formGroup.get('businessOwner')?.enable();
        formGroup.get('owners')?.disable();
      }
    });


    return formGroup;
  }

  public createOwnersArrayForm(): FormGroup {
    return this._fb.group({
      customer: this._customerFormService.createCustomerFormGroup(true),
      customerId: ''
    });
  }

  public createPriceDetailsFormGroup(): FormGroup {
    return this._fb.group({
      customerExpectedPrice: 0,
      reserve: 0,
      operatingCostStipulation: 0,
    })
  }

  public createNeededDocumentsFormGroup(): FormGroup {
    return this._fb.group({
      landZoning: DOCSTATUS.INACCURATE,
      developmentPlan: DOCSTATUS.INACCURATE,
      permitOfUsePlan: DOCSTATUS.INACCURATE,
      buildingPermit: DOCSTATUS.INACCURATE,
      floorPlan: DOCSTATUS.INACCURATE,
      operatingCosts: DOCSTATUS.INACCURATE,
      otherDocs: this._fb.array([]),
    })
  }

  public createOtherDocsArrayItemForm(): FormGroup {
    return this._fb.group({
      description: '',
      docStatus: DOCSTATUS.HANDEDOVER,
    })
  }

  public createPropertyEnergyPassFormGroup(): FormGroup {
    return this._fb.group({
      energyPassAvailable: ENERGYPASSAVAILABLE.ISAVAILABLE,
      energyPassResponsible: ENERGYPASSRESPONSIBLE.AGENT,
      energyPassDelivery: '',
      hwb: '',
      hwbClass: ENERGYPASSCLASS.NOTAVAILABLE,
      fGEE: '',
      fgeeClass: ENERGYPASSCLASS.NOTAVAILABLE,
      validUntil: [new Date().toISOString().substring(0, 10)],
    })
  }

  public createPropertyAttributesFormGroup(): FormGroup {
    return this._fb.group({
      beaconing: this.createBeaconingFormGroup(),
      heating: this.createHeatingFormGroup(),
      floors: this.createFloorsFormGroup(),
      structure: this.createStructureFormGroup(),
      connections: this.createConnectionsFormGroup()
    })
  }

  public createStructureFormGroup(): FormGroup {
    return this._fb.group({
      prefab: false,
      solid: false,
      brick: false,
      wood: false,
      ferroconcrete: false,
    })
  }

  public createConnectionsFormGroup(): FormGroup {
    return this._fb.group({
      electricity: false,
      highVoltage: false,
      gas: false,
      water: false,
      fountain: false,
      teleheating: false,
      canal: false,
      phone: false,
      tvCabel: false,
      tvSat: false,
      internet: false,
    })
  }

  public createFloorsFormGroup(): FormGroup {
    return this._fb.group({
      floorboards: false,
      screed: false,
      tiles: false,
      syntheticFloor: false,
      linoleum: false,
      parquet: false,
      carpet: false,
      terracotta: false,
      accessFloor: false,
      finishedParquet: false,
      granite: false,
      laminate: false,
      marble: false,
      stone: false,
      carpetTiles: false,
    })
  }

  public createHeatingFormGroup(): FormGroup {
    return this._fb.group({
      selfContainedCentralHeating: false,
      floorHeating: false,
      chimney: false,
      teleheating: false,
      centralHeating: false,
    })
  }

  public createBeaconingFormGroup(): FormGroup {
    return this._fb.group({
      alternative: false,
      electro: false,
      teleheating: false,
      gas: false,
      coal: false,
      oil: false,
      solar: false,
      thermalPower: false,
      geothermics: false,
      fluidGas: false,
      wood: false,
      airHeatPump: false,
      pellets: false,
      waterElectro: false,
    })
  }

  public createConditionFormGroup(): FormGroup {
    return this._fb.group({
      overall: CONDITION.NOTAVILABLE,
      floor: CONDITION.NOTAVILABLE,
      roof: CONDITION.NOTAVILABLE,
      windows: CONDITION.NOTAVILABLE,
      installer: CONDITION.NOTAVILABLE,
      electric: CONDITION.NOTAVILABLE,
      reconstruction: false,
      reconstructionPlan: '',
      reserveCoverage: false,
      hiddenFlaws: false,
      hiddenFlawsNotes: '',
      offBookCharges: false,
      offBookChargesNotes: ''
    });
  }

  public createUpdateObjectForm(): FormGroup {
    const formGroup = this._fb.group({
      objectType: [null, [Validators.required]],
      orderType: [null, [Validators.required]],
      objectStatus: ['active'],
      price: [0],
      priceDetails: this.createPriceDetailsFormGroup(),
      movingInDate: [null],
      movingInDateDetails: '',
      roomsAndArea: this.createRoomsAndAreaFormGroup(),
      condition: this.createConditionFormGroup(),
      inventoryList: this._fb.array([]),
      propertyNotes: this._fb.array([]),
      propertyAttributes: this.createPropertyAttributesFormGroup(),
      energyPass: this.createPropertyEnergyPassFormGroup(),
      additionalInformation: this.createAdditionalInformationFormGroup(),
      documents: this._fb.array([]),
      neededDocuments: this.createNeededDocumentsFormGroup(),
      images: this._fb.array([]),
      address: this.createAddressFormGroup(),
      ownerType: [CustomerType.Private],
      owners: this._fb.array([]),
      businessOwner: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
      })
    }) as FormGroup;

    formGroup.get('ownerType')?.valueChanges.pipe(
      startWith(CustomerType.Private)
    ).subscribe((value) => {
      if (value === CustomerType.Private) {
        formGroup.get('businessOwner')?.disable();
        formGroup.get('owners')?.enable();
      } else if (value === CustomerType.Business) {
        formGroup.get('businessOwner')?.enable();
        formGroup.get('owners')?.disable();
      }
    });


    return formGroup;
  }

  public createDocumentsArrayItemForm(): FormGroup {
    return this._fb.group({
      id: '',
      description: '',
      type: '',
      storageUrl: '',
      title: '',
      documentFrom: new Date().toISOString().substring(0, 10),
      state: ''
    });
  }

  public createImagesArrayItemForm(): FormGroup {
    return this._fb.group({
      id: '',
      description: '',
      type: '',
      storageUrl: ['', Validators.required],
      order: 0,
    });
  }

  public createPreListingObjectForm(): FormGroup {
    return this._fb.group({
      objectType: [null, [Validators.required]],
      objectStatus: ['draft'],
      orderType: [null],
      roomsAndArea: this.createRoomsAndAreaFormGroup(),
      price: [0],
      priceDetails: this.createPriceDetailsFormGroup(),
      address: this.createAddressFormGroup(),
      additionalInformation: this.createAdditionalInformationFormGroup(),
      preListingData: this.createPreListingDataFormGroup(),
      preListingReport: this._fb.group({
        url: [''],
      }),
    });
  }

  private createRoomsAndAreaFormGroup(): FormGroup {
    return this._fb.group({
      livingArea: this.createRoomsAndAreaItemFormGroup(),
      baseArea: this.createRoomsAndAreaItemFormGroup(),
      usableArea: this.createRoomsAndAreaItemFormGroup(),
      generalRoomsInformation: this.createGeneralRoomsInformationFormGroup(),
      toilets: this.createRoomsAndAreaItemFormGroup(),
      bathRooms: this.createRoomsAndAreaItemFormGroup(),
      storeRooms: this.createRoomsAndAreaItemFormGroup(),
      garden: this.createRoomsAndAreaItemFormGroup(),
      basement: this.createRoomsAndAreaItemFormGroup(),
      balcony: this.createRoomsAndAreaItemFormGroup(),
      loggia: this.createRoomsAndAreaItemFormGroup(),
      terrace: this.createRoomsAndAreaItemFormGroup(),
      parking: this.createRoomsAndAreaItemFormGroup(),
      garage: this.createRoomsAndAreaItemFormGroup(),
      otherRooms: this._fb.array([])
    });
  }

  private createGeneralRoomsInformationFormGroup(): FormGroup {
    return this._fb.group({
      count: [null],
      floorAmount: [null],
      floor: [null],
      elevator: false,
    });
  }

  public createOtherRoomsItemFormGroup(): FormGroup {
    return this._fb.group({
      title: '',
      area: [null],
      areaCa: true,
    });
  }

  public createRoomsAndAreaItemFormGroup(): FormGroup {
    return this._fb.group({
      roomCount: [null],
      area: [null],
      areaCa: true,
    });
  }

  public createInventoryListItemFormGroup(): FormGroup {
    return this._fb.group({
      title: '',
      notes: '',
    });
  }

  public createPropertyNotesItemFormGroup(): FormGroup {
    return this._fb.group({
      title: '',
      notes: '',
    });
  }

  public createAddressFormGroup(): FormGroup<AddressForm> {
    return this._fb.group({
      manualCreation: [false],
      country: '',
      city: '',
      zipCode: '',
      streetName: '',
      buildingNumber: '',
      doorNumber: '',
      stairway: '',
      longitude: 0,
      latitude: 0,
      state: '',
      displayAddress: '',
    });
  }

  private createAdditionalInformationFormGroup(): FormGroup {
    const currentYear = new Date().getFullYear();

    return this._fb.group({
      constructionYear: [0, [Validators.max(currentYear)]],
      constructionYearCa: false,
      cadastralCommunity: [''],
      entryNumber: [''],
      parcelNumber: [''],
      parcelNumbers: this._fb.array([]),
    });
  }

  private createPreListingDataFormGroup(): FormGroup {
    return this._fb.group({
      hasValuationLight: [false],
      hasLocationScore: [false],
      hasPOIs: [false],
      hasAccessibility: [false],
      hasDossier: [false],
      hasContaminatedSites: [false],
      hasHORAPass: [false],
      hasMaps: [false],
    });
  }

  public createPropertyInitialValue(creationSource?: string): ObjectCreation {
    return {
      creationSource: creationSource ?? '',
      objectType: 'house',
      orderType: 'buy',
      objectStatus: 'draft',
      address: {
        streetName: ''
      }
    }
  }

  public addCustomerValidators(customerGroup: FormGroup): void {
    const firstnameControl = customerGroup.get('firstname');
    const lastnameControl = customerGroup.get('lastname');
    const emailControl = customerGroup.get('contact.email');

    if (firstnameControl) {
      firstnameControl.setValidators([Validators.required]);
      firstnameControl.updateValueAndValidity();
    }
    if (lastnameControl) {
      lastnameControl.setValidators([Validators.required]);
      lastnameControl.updateValueAndValidity();
    }
    if (emailControl) {
      emailControl.setValidators([Validators.email, Validators.required]);
      emailControl.updateValueAndValidity();
    }

    customerGroup.markAllAsTouched();
    customerGroup.markAsDirty();
  }
}
