import { CustomerWithContact } from '@domains/customer';
import { createAction, props } from '@ngrx/store';
import { RentalOffer, RentalOfferSignatureCreation } from '../rental-offer.model';
import { AgentCompany } from "../../../common-models/agent-company";

export const LoadRentalOffers = createAction('[RentalOffer] Load Rental Offers', props<{ objectId: string }>());
export const LoadRentalOffersByCustomer = createAction('[RentalOffer] Load Rental Offers By Customer', props<{ customerId: string }>());
export const RentalOffersLoaded = createAction('[RentalOffer] Rental Offers Loaded', props<{ payload: RentalOffer[] }>());
export const RentalOffersLoadingFailed = createAction('[RentalOffer] Rental Offers Loading Failed');

export const LoadRentalOfferDetails = createAction('[RentalOffer] Load Rental Offer Details', props<{
  objectId: string;
  rentalOfferId: string;
}>());
export const RentalOfferDetailsLoaded = createAction('[RentalOffer] Rental Offer Details Loaded', props<{ payload: RentalOffer }>());
export const RentalOfferDetailsLoadingFailed = createAction('[RentalOffer] Rental Offer Details Loading Failed');

export const SaveRentalOffer = createAction('[RentalOffer] Save Rental Offer', props<{ rentalOffer: RentalOffer; isFinal?: boolean }>());
export const SaveRentalOfferSuccess = createAction('[RentalOffer] Rental Offer Saved');

export const SendTenantCustomerPortalNotification = createAction('[RentalOffer] Send Tenant Customer Portal Notification', props<{
  objectId: string;
  rentalOfferId: string;
}>());

export const CreateAndUploadRentalOfferDocumentFailed = createAction('[RentalOffer] Failed to create and upload rental offer document');
export const CreateRentalOfferSucceded = createAction('[RentalOffer] Succeeded to create and save Rental Offer');

export const UpdateRentalOfferCustomers = createAction(
  '[RentalOffer] Update Rental Offer Customers',
  props<{
    tenants: CustomerWithContact[];
    landlords: CustomerWithContact[];
    rentalOfferId: string;
    objectId: string;
  }>()
);

export const DeleteRentalOffer = createAction('[RentalOffer] Delete Rental Offer', props<{ rentalOfferId: string, objectId: string }>());
export const DeleteRentalOfferSuccess = createAction('[RentalOffer] Delete Rental Offer Succeeded', props<{ rentalOfferId: string }>());
export const DeleteRentalOfferFailed = createAction('[RentalOffer] Delete Rental Offer Failed', props<{ rentalOfferId: string }>());

export const UpdateRentalOfferMasterTemplate = createAction('[RentalOffer] Update Rental Offer Master Template', props<{ rentalOfferId: string, objectId: string }>());
export const ResetRentalOffer = createAction('[RentalOffer] Reset Rental Offer', props<{ objectId: string; rentalOfferId: string }>());

export const SignAndCompleteRentalOffer = createAction(
  '[RentalOffer] Sign And Complete Rental Offer',
  props<{ rentalOfferSignature: RentalOfferSignatureCreation }>()
);
export const DraftSignRentalOffer = createAction(
  '[RentalOffer] Draft Sign Rental Offer',
  props<{
    rentalOfferSignature: RentalOfferSignatureCreation,
    openEmailDialog: boolean,
  }>()
);

export const SendRentalOfferEmail = createAction(
  '[RentalOffer] Send Rental Offer Email',
  props<{
    rentalOfferId: string;
    emailData: any;
    agentCompany: AgentCompany;
  }>()
);

export const SendCustomerPortalLink = createAction(
  '[RentalOffer] Send Customer Portal Link',
  props<{
    objectId: string;
    rentalOfferId: string;
    handInOffer: boolean;
    emailData: any;
  }>()
);

export const SendLandlordCustomerPortalLink = createAction(
  '[RentalOffer] Send Landlord Customer Portal Link',
  props<{
    objectId: string;
    rentalOfferId: string;
    emailData: any;
  }>()
);
