import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from "@angular/material/tooltip";
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSidenavModule } from '@angular/material/sidenav';

import { GraphQLModule } from './services/graphql.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PostLoginComponent } from './post-login/post-login.component';
import { settingsReducer } from './store/settings/settings.reducers';
import { EmailsEffects } from './store/emails/emails.effects';
import { SettingsEffects } from './store/settings/settings.effects';
import { GlobalEffects } from './store/_global/global.effects';
import { globalReducer } from './store/_global/global.reducers';
import { ScreenSizeIndicatorModule } from './common-components/screen-size-indicator/screen-size-indicator.module';
import { SectionStartScreenFrameModule } from './common-components/section-start-screen-frame/section-start-screen-frame.module';
import { ImageManagementModule } from './common-components/image-management/image-management.module';
import { SidenavButtonComponent } from './sidenav-button/sidenav-button.component';
import { AvatarModule } from './common-components/avatar/avatar.module';
import { ActionPanelModule } from './common-components/action-panel/action-panel.module';
import { ProfileEffects } from './store/profile/profile.effects';
import { profileReducer } from './store/profile/profile.reducers';
import { SharedDomainsModule } from 'src/app/domains/shared-domains.module';
import { keycloak } from '../keycloak.gcp.staging.config';
import { PropupAgmModule } from './utils/propup-agm/propup-agm.module';
import { environment } from '../environments/environment';
import { BannerModule } from './common-components/banner/banner.module';
import { TeamsBannerModule } from './common-components/teams-banner/teams-banner.module';
import { SidenavComponent } from "./common-components/sidenav/sidenav.component";
import { SidenavLinkComponent } from "./common-components/sidenav-link/sidenav-link.component";
import { RightSidebarComponent } from './sidebar/sidebar.component';
import { PropupDateAdapter } from './services/date-adapter';
import { SharedModule } from "./shared/shared.module";
import { TranslationRootModule } from 'src/app/i18n/TranslationRootModule';
import * as Sentry from "@sentry/angular";

@NgModule({
  declarations: [
    AppComponent,
    PostLoginComponent,
    SidenavButtonComponent,
    SidenavComponent,
    SidenavLinkComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PropupAgmModule.forRoot({
      apiKey: 'AIzaSyDoNp2f-Mib1kt6wGuCHreGKZIODi0ujWM',
      libraries: ['places'],
    }),
    GraphQLModule,
    TranslationRootModule,
    MatSnackBarModule,
    ScreenSizeIndicatorModule,
    SectionStartScreenFrameModule,
    MatSelectModule,
    ImageManagementModule,
    MatSidenavModule,
    AvatarModule,
    BannerModule,
    TeamsBannerModule,
    ActionPanelModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    EffectsModule.forRoot([
      EmailsEffects,
      SettingsEffects,
      ProfileEffects,
      GlobalEffects,
    ]),
    SharedDomainsModule,
    KeycloakAngularModule,
    MatIconModule,
    StoreModule.forRoot(
      {
        global: globalReducer,
        settings: settingsReducer,
        profile: profileReducer,
      },
      {}
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict the extension to log-only mode
    }),
    MatTooltipModule,
    RightSidebarComponent,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enablePWA,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  exports: [],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'de-AT' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: PropupDateAdapter },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: keycloak.initializeKeycloak,
      deps: [KeycloakService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) { }
}
