import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Language } from './config';
import { mapCountrySettingsToLanguage } from './mapCountrySettingsToLanguage';
import { CountrySettings } from '@types';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  constructor(private _translateService: TranslateService) { }

  init() {
    this._translateService.setDefaultLang(Language.AT);
    this._translateService.use(Language.AT);
  }

  instant(key: string, interpolateParams?: Object): string {
    return this._translateService.instant(key, interpolateParams);
  }

  changeLanguage(language: Language) {
    this._translateService.use(language);
  }

  applyCountrySettingsLanguage(countrySettings: CountrySettings) {
    this.changeLanguage(mapCountrySettingsToLanguage(countrySettings));
  }
}