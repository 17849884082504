import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { hopSelectors, HandoverProtocolFeatureState } from './store';
import { HandoverProtocolActions } from './store/action-types';
import { HandoverProtocolApiFacade } from './handover-protocol-api.facade';
import { Observable } from 'rxjs';
import { DocumentService } from 'services/document.service';
import { getAgentCompany } from 'src/app/common-models/mapping';
import { AuthService } from 'services/auth.service';
import { catchError, first, map, tap } from 'rxjs/operators';
import {
  HandoverProtocolCreation,
  HandoverProtocolSignatureCreation,
  HandoverProtocol,
} from './handover-protocol.model';
import { EmailPreviewBodyDto } from '@types';

@Injectable({ providedIn: 'root' })
export class HandoverProtocolDataFacade {
  handoverProtocols$ = this._store$.select(hopSelectors.handoverProtocols);
  handoverProtocolsLoading$ = this._store$.select(hopSelectors.handoverProtocolsLoading);
  handoverProtocolDetails$ = this._store$.select(hopSelectors.handoverProtocolDetails);
  handoverProtocolDetailsLoading$ = this._store$.select(hopSelectors.handoverProtocolDetailsLoading);
  savingProcessLoading$ = this._store$.select(hopSelectors.savingProcessLoading);
  savingProcessHasError$ = this._store$.select(hopSelectors.savingProcessHasError);

  constructor(
    private readonly _store$: Store<HandoverProtocolFeatureState>,
    private readonly _hopApiFacade: HandoverProtocolApiFacade,
    private readonly _authService: AuthService,
    private readonly _docService: DocumentService
  ) { }

  public loadHandoverProtocols(objectId: string): void {
    this._store$.dispatch(HandoverProtocolActions.LoadHandoverProtocols({ objectId }));
  }

  public loadHandoverProtocolsByCustomer(customerId: string): void {
    this._store$.dispatch(HandoverProtocolActions.LoadHandoverProtocolsByCustomer({ customerId }));
  }

  public loadHandoverProtocolDetails(objectId: string, hopId: string): void {
    this._store$.dispatch(HandoverProtocolActions.LoadHandoverProtocolDetails({ objectId, hopId }));
  }

  public saveHandoverProtocol(handoverProtocol: HandoverProtocolCreation, isFinal: boolean = false, shouldPrint: boolean = false): void {
    this._store$.dispatch(HandoverProtocolActions.SaveHandoverProtocol({ handoverProtocol, isFinal, shouldPrint }));
  }

  public sendHandoverProtocolEmail(objectId: string, handoverProtocolId: string, emailData: any): void {
    this._store$.dispatch(HandoverProtocolActions.SendHandoverProtocolEmail({
      objectId,
      handoverProtocolId,
      emailData,
    }));
  }

  public signAndCompleteHandoverProtocol(handoverProtocolSignature: HandoverProtocolSignatureCreation): void {
    this._store$.dispatch(HandoverProtocolActions.SignAndCompleteHandoverProtocol({ handoverProtocolSignature }));
  }

  public draftSignHandoverProtocol(
    handoverProtocolSignature: HandoverProtocolSignatureCreation,
    openEmailDialog = true,
  ): void {
    this._store$.dispatch(HandoverProtocolActions.DraftSignHandoverProtocol({
      handoverProtocolSignature,
      openEmailDialog,
    }));
  }

  public failedCreateAndUploadHandoverProtocolDocument(): void {
    this._store$.dispatch(HandoverProtocolActions.CreateAndUploadHandoverProtocolDocumentFailed());
  }

  public deleteHandoverProtocol(hopId: string, objectId: string): void {
    this._store$.dispatch(HandoverProtocolActions.DeleteHandoverProtocol({ hopId, objectId }));
  }

  public getHandoverProtocolEmailVariables$(objectId: string, handoverProtocolId: string): Observable<any> {
    const agentCompany = getAgentCompany(this._authService);
    return this._hopApiFacade.getHandoverProtocolEmailVariables$(objectId, handoverProtocolId, agentCompany);
  }

  public createHandoverProtocol$(hop: HandoverProtocolCreation): Observable<HandoverProtocol> {
    return this._hopApiFacade.saveHandoverProtocol$(hop);
  }

  public compressFiles$(objectId: string, filePaths: string[], fileNames: string[]): Observable<string> {
    return this._hopApiFacade.compressFiles$(objectId, filePaths, fileNames);
  }

  public getHandoverProtocolDetails$(objectId: string, hopId: string): Observable<HandoverProtocol> {
    return this._hopApiFacade.loadHandoverProtocolDetails$(objectId, hopId);
  }

  public createHandoverProtocolDocument$(objectId: string, hopId: string): Observable<string> {
    const agentCompany = getAgentCompany(this._authService);
    return this._hopApiFacade.createHandoverProtocolDocument$(objectId, hopId, agentCompany);
  }

  public getSignedHOPsAmount$(objectId: string): Observable<{ amount: number }> {
    return this._hopApiFacade.getSignedHOPsAmount$(objectId);
  }

  public downloadSignedHOPsDocs$(objectId: string): Observable<string> {
    return this._hopApiFacade.getSignedHOPsDocsUrl$(objectId).pipe(
      map(data => data.storageUrl),
      tap((storageUrl) => {
        if (storageUrl) {
          const lastIndex = storageUrl.lastIndexOf('/');
          const a = document.createElement('a');
          a.download = storageUrl.substring(lastIndex + 1);
          a.href = storageUrl;
          document.body.appendChild(a);
          a.click();
        }
      })
    );
  }


  public showHandoverProtocolDocument(objectId: string, hopId: string): void {
    this.createHandoverProtocolDocument$(objectId, hopId).pipe(
      first(),
      tap((url) => {
        this._docService.openDocumentLink(url);
      }),
      catchError((err) => {
        this.failedCreateAndUploadHandoverProtocolDocument();
        throw err;
      }),
    ).subscribe();
  }
}
