import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerCreation, CustomerWithContact } from '@domains/customer';

@Injectable({ providedIn: 'root' })
export class CustomerFormService {

  constructor(private readonly _fb: FormBuilder) { }

  createCustomerFormGroup(isOptional?: boolean): FormGroup {
    if (isOptional) {
      return this._buildOptionalCustomerFormGroup();
    }

    return this._buildRequiredCustomerFormGroup();
  }

  private getPepForm(): FormGroup {
    return this._fb.group({
      isPep: null,
      wasPepLastYear: null,
      isFamilyMemberPep: null,
      isKnownToBeCloseToPep: null,
      firstName: ['', [this._pepNameValidator]],
      lastName: ['', [this._pepNameValidator]],
    });
  }

  public createImagesArrayItemForm(): FormGroup {
    return this._fb.group({
      id: '',
      description: '',
      type: '',
      storageUrl: '',
    });
  }

  convertToFormGroup(customer: CustomerWithContact): FormGroup {
    if (customer === undefined) {
      return this._fb.group({});
    }

    const contact = customer.contact ?? {};
    const address = contact?.address ?? {};
    const IDImages = customer.IDImages ?? [];

    const IDImagesFormArray = this._fb.array([]);

    if (IDImages && IDImages.length) {
      IDImages.forEach((img: any) => {
        const imageForm = this.createImagesArrayItemForm();
        imageForm.patchValue(img);

        IDImagesFormArray.push(imageForm as any);
      });
    }

    return this._fb.group({
      id: customer.id,
      birthdate: customer.birthdate,
      title: customer.title,
      firstname: customer.firstname,
      lastname: customer.lastname,
      nationality: customer.nationality,
      isConsumer: customer.isConsumer ?? true,
      contact: this._fb.group({
        address: this._fb.group({
          country: address?.country,
          city: address?.city,
          zipCode: address?.zipCode,
          streetName: address?.streetName,
          buildingNumber: address?.buildingNumber,
          doorNumber: address?.doorNumber,
          stairway: address?.stairway,
          longitude: address?.longitude,
          latitude: address?.latitude,
          displayAddress: '',
        }),
        email: [contact.email, Validators.email],
        phone: contact.phone,
      }),
      pep: this._fb.group({
        isPep: customer.pep?.isPep ?? null,
        wasPepLastYear: customer.pep?.wasPepLastYear ?? null,
        isFamilyMemberPep: customer.pep?.isFamilyMemberPep ?? null,
        isKnownToBeCloseToPep: customer.pep?.isKnownToBeCloseToPep ?? null,
        firstName: customer.pep?.firstName ?? '',
        lastName: customer.pep?.lastName ?? '',
      }),
      IDImages: IDImagesFormArray,
    });
  }

  createBusinessCustomerFormGroup(): FormGroup {
    return this._fb.group({
      id: undefined,
      companyNumber: ['', [Validators.required]],
      companyRegister: '',
      contact: this._fb.group({
        address: this._fb.group({
          country: [''],
          city: [''],
          zipCode: [''],
          streetName: [''],
          buildingNumber: [''],
          doorNumber: [''],
          stairway: [''],
          displayAddress: [''],
        }),
        email: ['', [Validators.email, Validators.required]],
        phone: '',
      }),
      customers: this._fb.array([]),
    });
  }

  prepareCustomerToPersist(customer: any): CustomerCreation {
    const copy = JSON.parse(JSON.stringify(customer));
    delete copy.contact?.address?.displayAddress;
    return copy;
  }

  addCustomerValidators(customerGroup: FormGroup): void {
    const firstnameControl = customerGroup.get('firstname');
    const lastnameControl = customerGroup.get('lastname');
    const emailControl = customerGroup.get('contact.email');

    if (firstnameControl) {
      firstnameControl.setValidators([Validators.required]);
      firstnameControl.updateValueAndValidity();
    }
    if (lastnameControl) {
      lastnameControl.setValidators([Validators.required]);
      lastnameControl.updateValueAndValidity();
    }
    if (emailControl) {
      emailControl.setValidators([Validators.email, Validators.required]);
      emailControl.updateValueAndValidity();
    }

    customerGroup.markAllAsTouched();
    customerGroup.markAsDirty();
  }

  private _buildRequiredCustomerFormGroup(): FormGroup {
    return this._fb.group({
      id: undefined,
      birthdate: undefined,
      title: '',
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      nationality: '',
      isConsumer: true,
      contact: this._fb.group({
        address: this._fb.group({
          country: [''],
          city: [''],
          zipCode: [''],
          streetName: [''],
          buildingNumber: [''],
          doorNumber: [''],
          stairway: [''],
          longitude: [0],
          latitude: [0],
          displayAddress: [''],
        }),
        email: ['', [Validators.email, Validators.required]],
        phone: '',
      }),
      pep: this.getPepForm(),
      IDImages: this._fb.array([]),
    });
  }

  private _buildOptionalCustomerFormGroup(): FormGroup {
    return this._fb.group({
      id: [undefined],
      birthdate: [undefined],
      title: '',
      firstname: '',
      lastname: '',
      nationality: [''],
      isConsumer: true,
      contact: this._fb.group({
        address: this._fb.group({
          country: [''],
          city: [''],
          zipCode: [''],
          streetName: [''],
          buildingNumber: [''],
          doorNumber: [''],
          stairway: [''],
          longitude: [0],
          latitude: [0],
          displayAddress: [''],
        }),
        email: ['', Validators.email],
        phone: [''],
      }),
      pep: this.getPepForm(),
      IDImages: this._fb.array([]),
    });
  }

  private _pepNameValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    const isPep = formControl.parent.get('isPep')?.value;
    const wasPepLastYear = formControl.parent.get('wasPepLastYear')?.value;
    const isFamilyMemberPep = formControl.parent.get('isFamilyMemberPep')?.value;
    const isKnownToBeCloseToPep = formControl.parent.get('isKnownToBeCloseToPep')?.value;

    const shouldEnterName = isPep || wasPepLastYear || isFamilyMemberPep || isKnownToBeCloseToPep;

    if (shouldEnterName) {
      return Validators.required(formControl);
    }

    return null;
  }
}
