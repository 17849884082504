import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { FormGroup } from '@angular/forms';

import { CustomerApiFacade } from './customer-api.facade';
import { CustomerFormService } from './customer-form.service';
import { CustomerCreation, CustomerDetails, CustomerWithContact } from './customer.model';
import { CustomerActions } from './store/action-types';
import { CustomerFeatureState, customerSelectors } from './store';
import { SnackbarService } from 'services/snackbar.service';
import { tap } from 'rxjs/operators';
import { Country } from '@domains/viewing-pass';
import { PagingOptions } from 'src/app/common-models/paging-options';

@Injectable({ providedIn: 'root' })
export class CustomerDataFacade {
  customersListData$ = this._store$.select(customerSelectors.customersListData);
  customersListMetadata$ = this._store$.select(customerSelectors.customersListMetadata);
  customersLoading$ = this._store$.select(customerSelectors.customersLoading);
  customerDetails$ = this._store$.select(customerSelectors.customerDetails);
  customerDetailsLoading$ = this._store$.select(customerSelectors.customerDetailsLoading);

  constructor(
    private readonly _store$: Store<CustomerFeatureState>,
    private readonly _snackbar: SnackbarService,
    private readonly _customerApiFacade: CustomerApiFacade,
    private readonly _customerFormService: CustomerFormService
  ) { }

  loadCustomers(pagingOptions: PagingOptions, searchText?: string): void {
    this._store$.dispatch(CustomerActions.LoadCustomers({ pagingOptions, searchText }));
  }

  loadCustomerDetails(id: string): void {
    this._store$.dispatch(CustomerActions.LoadCustomerDetails({ payload: { id } }));
  }

  saveRentalContractCustomers(
    contractTenants: CustomerWithContact[],
    contractLandlords: CustomerWithContact[],
    rentalContractId: string,
    objectId: string,
  ): void {
    this._store$.dispatch(CustomerActions.SaveRentalContractCustomers({
      contractTenants,
      contractLandlords,
      rentalContractId,
      objectId,
    }));
  }

  triggerExternalCustomersLoadingSucceeded(): void {
    this._store$.dispatch(CustomerActions.TriggerExternalCustomersLoadingSucceeded());
  }

  public deleteCustomer$(customerId: string): void {
    return this._store$.dispatch(CustomerActions.DeleteCustomer({ customerId }))
  }

  searchCustomer$(firstName: string, lastName: string): Observable<CustomerWithContact[]> {
    return this._customerApiFacade.searchCustomer$(firstName, lastName);
  }

  getCustomerById$(id: string): Observable<CustomerWithContact> {
    return this._customerApiFacade.getCustomerById$(id);
  }

  getCountries$(): Observable<Country[]> {
    return this._customerApiFacade.getCountries$();
  }

  getExternalCustomerById$(id: string): Observable<CustomerDetails> {
    return this._customerApiFacade.getExternalCustomerById$(id);
  }

  importEdirealCustomer$(data: CustomerCreation): Observable<boolean> {
    return this._customerApiFacade.importEdirealCustomer$(data).pipe(
      tap((response) => {
        if (response) {
          this._snackbar.showSnackbar(
            'Kunde erfolgreich importiert',
            'mat-primary',
            true
          );
        } else {
          this._snackbar.showSnackbar(
            'Kunde konnte nicht importiert werden',
            'mat-warn',
            true
          );
        }
      })
    )
  }

  saveCustomer$(customerForm: FormGroup): Observable<CustomerWithContact | null> {
    customerForm.updateValueAndValidity();
    customerForm.markAllAsTouched();

    if (customerForm.invalid) {
      console.warn('Customer Form Invalid!', customerForm.value);
      return of(null);
    }

    const customer = this._customerFormService.prepareCustomerToPersist(customerForm.value);
    const mappedCustomerData = this._mapCustomerData(customer);

    if (!this._isValidCustomer(mappedCustomerData as any)) {
      return of(null);
    }

    return this._customerApiFacade.createCustomer$(mappedCustomerData);
  }

  private _mapCustomerData(customer: any): CustomerCreation {
    const IDImages = (customer.IDImages ?? []).map(
      ({ id, storageUrl, type, description }: any) => ({ id, storageUrl, type, description })
    );

    return {
      ...customer,
      IDImages,
    };
  }

  private _isValidCustomer(customer: CustomerWithContact): boolean {
    const firstnameValid = !!customer.firstname;
    const lastnameValid = !!customer.lastname;
    const emailValid = !!customer.contact.email;

    return firstnameValid && lastnameValid && emailValid;
  }
}
